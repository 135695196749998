import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles

const EmailEditor = ({
  createCampaignFields,
  handleOnchange,
  handleOnBlur,
}) => {
  // State for managing the email body
  const [emailBody, setEmailBody] = useState(
    createCampaignFields.emailBody.value
  );
  const reactQuillRef = React.useRef();

  // Sync the external state when it changes
  useEffect(() => {
    setEmailBody(createCampaignFields.emailBody.value);
  }, [createCampaignFields.emailBody.value]);

  // Sync state when the email body or switch values change
  useEffect(() => {
    if (
      createCampaignFields.removePoweredByFlag &&
      createCampaignFields.removeUnsubscribeFlag
    ) {
      const updatedContent = ensurePlaceholders(
        createCampaignFields.emailBody.value || ""
      );
      setEmailBody(updatedContent);
      handleOnchange("emailBody", updatedContent); // call the external onchange handler
    }
  }, [
    createCampaignFields.removePoweredByFlag,
    createCampaignFields.removeUnsubscribeFlag,
  ]);

  //Ensure placeholders are managed properly based on switch states
  const ensurePlaceholders = (content) => {
    const unsubscribeTag = "{unsubscribe-link}";
    const sentViaTag = "{sent-via-bulkcalendar-link}";

    // Step 1: Remove any existing footer
    // This regex matches a <p> tag containing either an "unsubscribe-link" or "sent-via-bulkcalendar-link",
    // optionally separated by a "|" symbol and optionally repeated. It also accounts for any whitespace
    // around the elements and is case-insensitive. The pattern ensures it captures the entire <p> tag
    // and its contents.
    const footerRegex =
      /<p[^>]*?>\s*(?:{unsubscribe-link}|{sent-via-bulkcalendar-link})(?:\s*\|\s*(?:{unsubscribe-link}|{sent-via-bulkcalendar-link}))?\s*<\/p>/gi;

    content = content.replace(footerRegex, ""); // Remove any footer matching the criteria
    content = content.replace(unsubscribeTag, "");
    content = content.replace(sentViaTag, "");

    // Step 2: Dynamically construct the new footer based on toggle states
    let newFooter = "";

    if (createCampaignFields.removeUnsubscribeFlag.value === "NO") {
      newFooter += unsubscribeTag;
    }
    if (createCampaignFields.removePoweredByFlag.value === "NO") {
      if (newFooter) {
        newFooter += " | "; // Add separator if unsubscribe is already included
      }
      newFooter += sentViaTag;
    }

    // Step 3: Append the new footer if it doesn’t already exist
    if (newFooter) {
      const newFooterTag = `<p id="footerLink">${newFooter}</p>`;
      content += newFooterTag;
    }
    return content;
  };

  // Handler for content change in the editor
  const handleEditorChange = (value) => {
    setEmailBody(value);
    handleOnchange("emailBody", value); // call the external onchange handler
  };

  // Handler for blur event (when editor loses focus)
  const handleEditorBlur = () => {
    const updatedContent = ensurePlaceholders(emailBody, "toggle state");
    setEmailBody(updatedContent);
    handleOnBlur(true, "emailBody", updatedContent);
  };

  //To prevent the user from exceeding the 6000 input limit
  const checkCharacterCount = (event) => {
    const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    if (unprivilegedEditor.getLength() >= 6000 && event.key !== "Backspace")
      event.preventDefault();
  };

  return (
    <div>
      <ReactQuill
        ref={reactQuillRef}
        onKeyDown={(e) => checkCharacterCount(e)}
        // style={{ height: "20rem" }}
        theme="snow"
        placeholder="Email Body"
        modules={{
          toolbar: [
            ["bold", "italic", "underline", "link"], // toggled buttons
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ font: [] }],
          ],
        }}
        value={emailBody}
        onChange={handleEditorChange}
        onBlur={handleEditorBlur}
        className="email-editor"
      />
      {!createCampaignFields.emailBody.isValid && (
        <div style={{ color: "red", fontSize: 12 }}>
          {createCampaignFields.emailBody.errMsg}
        </div>
      )}
    </div>
  );
};

export default EmailEditor;
