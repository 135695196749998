export const inputBorderColor = "#dee2e6";
export const inputTextColor = "#32325d"; //Changed the color of text match other input text color
export const cyanBlue = "#11cdef";
export const selectedColor = "#f6f9fc";
export const statusColor1 = "#31928b";
export const primaryConfigColor = "#1aae6f";
export const lightBlue = "#0000FFBF";
export const logoTopColor = "#EF6019";
export const googleBtnColor = "#00639b";
export const headerColor = "#0da5c0";
//youtube embeded links

//View link of create campaign
export const campaignGuide =
  "https://www.youtube.com/embed/zwLZqUucEr8?si=i6p9uPm6I0boeXs7?&rel=0";

//Campaign status constant for IN-PROGRESS
export const CAMPAIGN_STATUS_IN_PROGRESS = "IN-PROGRESS";

export const USD = "USD";
export const INR = "INR";

//roles
export const ROLES = {
  tenantAdmin: "TENANT_ADMIN",
  tenantUser: "TENANT_USER",
  tenantAccountManager: "TENANT_ACCOUNT_MANAGER", //TENANT_ACCOUNT_MANAGER new role introducted to make the configuration related route available
};

export const BULK_CALENDAR_SITE = "https://www.bulkcalendar.com";

export const BOOK_DEMO_LINK = "https://calendar.app.google/gUHbE37gqKGSs3im9";

export const CONTACT_US = [
  {
    icon: "fa-envelope",
    color: "text-blue",
    action: "mailto:support@bulkcalendar.co",
    label: "support@bulkcalendar.co",
    copyText: "support@bulkcalendar.co",
  },
  {
    icon: "fab fa-whatsapp fa-lg",
    color: "text-green",
    action: "https://wa.me/message/7V2R562INJAWH1",
    label: "Whatsapp Chat/Call",
  },
  {
    icon: "fa-phone",
    color: "text-info",
    action: "tel:+918625080811",
    label: "+91-8625-080811",
    copyText: "+918625080811",
  },
];

export const mailingServices = [
  { id: 4, name: "Gmail_icon_(2020).svg.png" },
  { id: 2, name: "BrevoLogo.png" },
  { id: 1, name: "compressed-original.webp" },
  { id: 6, name: "Mailgun-Light-Vector-Logo.png" },
  { id: 3, name: "SMTP.png" },
];
