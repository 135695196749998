import { cancelPlan } from "action/Billing/plans";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  InputGroupText,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";

const CancelPlanModal = ({ changePlanModal, changePlan, isPasswordEmpty }) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState("confirm"); // Step: 'confirm', 'questions', 'password'
  const [selectedOption, setSelectedOption] = useState(""); // State for selected radio button
  const [feedback, setFeedback] = useState("");
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [password, setPassword] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = (field) => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async () => {
    let data = {};
    if (selectedOption !== "Other") {
      data = { remark: selectedOption, tenantPassword: password };
    } else {
      data = { remark: feedback, tenantPassword: password };
    }
    const res = await cancelPlan(data);
    if (res.code === 200) {
      handleModal();
      Swal.fire({
        position: "middle",
        icon: "success",
        title: "Plan cancel successful!",
        text: res.message,
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: res.code + "Error",
        text: res.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleModal = () => {
    changePlan(false);
    setCurrentStep("confirm");
    setSelectedOption("");
    setFeedback("");
    setPassword("");
  };

  const renderConfirmStep = () => (
    <ModalBody>
      <p className="mt--4">
        Are you sure you want to cancel your subscription?
      </p>
      <div className="d-flex justify-content-end">
        <Button onClick={() => handleModal()} color="warning">
          Cancel
        </Button>
        <Button
          className="mr-2"
          color="secondary"
          onClick={() => setCurrentStep("questions")}
        >
          Yes
        </Button>
      </div>
    </ModalBody>
  );

  const renderQuestionsStep = () => (
    <ModalBody>
      <p className="mt--4 pb-3">
        We would appreciate it if you could share the specific reason(s) for
        canceling your subscription. Your feedback is valuable to us and will
        help us improve our services and better meet the needs of our customers
        in the future.
      </p>
      <div className="px-4 pt-4 mt--4">
        {[
          "  Our users are not utilizing the service enough to justify the ongoing expense.",

          "Our user's needs are changed, making the service no longer relevant or necessary.",

          "There are frequent bugs, downtime, or technical problems can drive users away.",

          " We want to avoid unwanted auto-renewal charges.",

          " We want to cancel due to personal or business financial constraints. ",

          "Other",
        ].map((text, index) => (
          <Row key={index} className="px-3">
            <Input
              type="radio"
              name="cancel-reason"
              value={text}
              checked={selectedOption === text}
              onChange={handleOptionChange}
            />
            <p>{text}</p>
          </Row>
        ))}
        {selectedOption === "Other" ? (
          <Input
            placeholder="Please share the details here"
            value={feedback}
            onChange={handleFeedbackChange}
          />
        ) : null}
      </div>
      <div className="d-flex pt-5 justify-content-end">
        <Button color="warning" onClick={() => setCurrentStep("confirm")}>
          Back
        </Button>
        <Button
          className="mr-2"
          color="secondary"
          onClick={() => setCurrentStep("password")}
          disabled={selectedOption === "Other" ? !feedback : !selectedOption}
        >
          Next
        </Button>
      </div>
    </ModalBody>
  );
  const renderPasswordStep = () => (
    <ModalBody>
      {!isPasswordEmpty ? (
        <>
          <InputGroup className="input-group-merge input-group-alternative  mb-3 mt--3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fa fa-lock" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type={passwordVisible ? "text" : "password"}
              placeholder="Enter the Password"
              value={password}
              onChange={handlePasswordChange}
            />
            <InputGroupText>
              <i
                className={`fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`}
                aria-hidden="true"
                onClick={() => togglePasswordVisibility("password")}
                style={{ cursor: "pointer" }}
              ></i>
            </InputGroupText>
          </InputGroup>
          <div className="d-flex pt-3 justify-content-end">
            <Button color="warning" onClick={() => setCurrentStep("questions")}>
              Back
            </Button>
            <Button
              className="mr-2"
              color="secondary "
              onClick={handleSubmit}
              disabled={!password}
            >
              Submit
            </Button>
          </div>
        </>
      ) : (
        <>
          <p className="mt--5 text-md ml--1 mt--3 ">
            Please set the account password from Profile menu to cancel the
            subscription.
          </p>
          <div className="d-flex pt-3 justify-content-end">
            <Button color="warning" onClick={() => setCurrentStep("questions")}>
              Back
            </Button>
            <Button
              className="mr-2"
              color="secondary "
              onClick={(e) => navigate("/a/user/profile")}
              // disabled={!password}
            >
              Set Password
            </Button>
          </div>
        </>
      )}
    </ModalBody>
  );

  // Determine the modal size class based on the current step
  const modalSizeClass = currentStep === "questions" ? "modal-lg" : "modal-sm";

  return (
    <Modal
      isOpen={changePlanModal}
      toggle={() => handleModal()}
      className={`modal-dialog-centered ${modalSizeClass}`}
    >
      <ModalHeader className="mb-1">
        {currentStep === "confirm"
          ? "Confirm Cancellation"
          : currentStep === "questions"
          ? "Provide Feedback"
          : !isPasswordEmpty
          ? "Enter Password"
          : "Set Password"}
      </ModalHeader>
      {currentStep === "confirm" && renderConfirmStep()}
      {currentStep === "questions" && renderQuestionsStep()}
      {currentStep === "password" && renderPasswordStep()}
    </Modal>
  );
};

export default CancelPlanModal;
