import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  Modal,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";
import ConfigTable from "./ConfigTable";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  inputBorderColor,
  inputTextColor,
  mailingServices,
} from "../../../components/Common/constants";
import {
  mailingServiceName,
  mailingServiceWeb,
  serviceInfo,
  serviceNote,
} from "../constants";
import NotificationAlert from "react-notification-alert";
import {
  getMailingServiceList,
  addEmailConfig,
  listEmailConfig,
} from "action/Settings/emailConfiguration";
import { GOOGLE_CONFIG } from "API/apiConstants";
import Warning from "components/Common/Warning";
import { emailRegex } from "components/CommonFunctions";
import { useLocation } from "react-router-dom";

function EmailConfiguration({
  fromCalendar,
  toggleAddConfigModal,
  modalNotify,
  createCampaignFields,
  campaignCode,
}) {
  // States for all fields
  const [configData, setConfigData] = useState({
    mailingService: {
      value: { ID: 0, name: "" },
      isValid: true,
      errorMsg: "",
    },
    integrationMethod: { value: "API", isValid: true, errorMsg: "" },
    title: { value: "", isValid: true, errorMsg: "" },
    smtpValueJson: {
      smtpServerURL: {
        value: "smtp.sendgrid.net",
        isValid: true,
        errorMsg: "",
      },
      encryptionType: {
        value: {
          port: "",
          encryptionType: "",
        },
        isValid: true,
        errorMsg: "",
      },
      port: { value: "", isValid: true, errorMsg: "" },
      accessKey: { value: "", isValid: true, errorMsg: "" },
      secretKey: { value: "", isValid: true, errorMsg: "" },
      senderName: { value: "", isValid: true, errorMsg: "" },
      email: { value: "", isValid: true, errorMsg: "" },
      replyToEmail: { value: "", isValid: true, errorMsg: "" },
    },
    apiValueJson: {
      senderName: { value: "", isValid: true, errorMsg: "" },
      email: { value: "", isValid: true, errorMsg: "" },
      replyToEmail: { value: "", isValid: true, errorMsg: "" },
      apiKey: { value: "", isValid: true, errorMsg: "" },
      mailgunDomain: { value: "", isValid: true, errorMsg: "" },
    },
  });
  const [selectedMailingService, setSelectedMailingService] = React.useState(
    {}
  );
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [testButtonDisabled, setTestButtonDisabled] = useState(true);
  const [showLoaderOnClick, setShowLoaderOnClick] = useState(false);
  const [newRecordAdded, setNewRecordedAdded] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showApiKey, setShowApiKey] = useState(false);

  const dispatch = useDispatch();
  // Pass state from the dashboard to the add Integrations
  // to autoFocus on title Input, if the user navigates directly from the dashboard.
  const location = useLocation();
  const { autoFocus, scrollList } = location.state || {};
  const inputRef = useRef(null);
  const listMailingService = useSelector(
    (state) => state.EmailConfigReducers.mailingServiceList
  );
  const configListRef = useRef(null);
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const notify = (type, message) => {
    if (isNotificationOpen) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };

    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };

  // Mailing service information
  const mailVideo = {
    1: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/GRP7SIXS90E?si=oWXvmzLKYqUI6aOG?rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ),
    3: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/GRP7SIXS90E?si=oWXvmzLKYqUI6aOG?rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ),
    2: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/JiHId_rPahU?si=4SIJ8rLNr7NLy5Yu?rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ),
    MailGun: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/NKMMpgJTDmY?si=AWIQ7O-Ut3IHKqWG?rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ),
    AWSSES: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/gVRTKuMFc0c?si=6DpQC25s0B93O2qt?rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ),
    ElasticEmail: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/JGwJ8rBvvJo?si=ylWuRe4DhmZm1n9d?rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ),
    Mailjet: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/t0lVxW30GuY?si=fNROFRiujc8B0XYG?rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ),
    4: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/Muhc63a_W9w?si=YWmGz5zY_SR2cnTT"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    ),
  };

  // Responsive css
  const responsiveStyles = {
    formField: {
      marginBottom: "1rem",
      display: "flex",
      alignItems: "flex-start",
      flexDirection: window.innerWidth > 768 ? "row" : "column",
    },
    inputContainer: {
      width: window.innerWidth > 768 ? "80%" : "100%",
    },
  };

  const rowRef = useRef(null); // Reference for scrolling

  useEffect(() => {
    if (selectedMailingService && window.innerWidth < 1000) {
      rowRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedMailingService]);

  useEffect(() => {
    //Done to make the text and logo responsive
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus(); // Focus the input if autoFocus is true
    }
  }, [autoFocus]);

  useEffect(() => {
    const styleSheet = document.styleSheets[0]; // Get the first stylesheet
    const keyframes = `
      @keyframes lineAnimation {
        0% { left: 50%; width: 0; }
        50% { left: 0; width: 100%; }
        100% { left: 0; width: 100%; }
      }
    `;

    // Append keyframes to the stylesheet
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  }, []);
  //handle error for add Integrations
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const error = searchParams.get("error");
    const success = searchParams.get("success");

    if (success) {
      getMailingServiceList(dispatch);
      notify("success", "Integrations added successfully");
    } else if (error) {
      notify("error", "You have not provided sufficient permission.");
    }
    // Remove search params after handling
    const newURL = window.location.pathname; // Retains only the path without query params
    window.history.replaceState(null, "", newURL); // Update URL without reloading
  }, [dispatch]);

  //api call to get maling service list
  useEffect(() => {
    getMailingServiceList(dispatch);
  }, []);

  //to check if all fields of an object is filled and isValid
  const checkObjectValueeValidAndNonEmpty = (obj) => {
    let isValid = false;
    for (const key in obj) {
      if (
        key === "encryptionType" &&
        obj[key].value.encryptionType.trim().length > 0 &&
        obj[key].isValid
      ) {
        isValid = true;
      } else if (
        typeof obj[key].value === "string" &&
        obj[key].value.trim().length > 0 &&
        obj[key].isValid
      ) {
        isValid = true;
      } else if (
        typeof obj[key].value === "number" &&
        obj[key].value > 0 &&
        obj[key].isValid
      ) {
        isValid = true;
      } else {
        return false;
      }
    }
    return isValid;
  };

  //to check if according to integration method all fields are filler or not
  useEffect(() => {
    if (
      configData.title.value?.trim().length > 0 &&
      configData.mailingService.value.ID > 0 &&
      configData.integrationMethod.value !== ""
    ) {
      if (
        configData.integrationMethod.value === "SMTP" &&
        checkObjectValueeValidAndNonEmpty(configData.smtpValueJson)
      ) {
        setTestButtonDisabled(false); // enable the button
      } else if (
        // in case of mailgun from configData object whole apiJson is sent
        // and in case of other mailing services object expect mailgunDomain key & value
        // is sent and the entries are convert the object into array in which mailgun domain is remove
        // and with fromEntries again that array is convert into object
        configData.integrationMethod.value === "API" &&
        checkObjectValueeValidAndNonEmpty(
          configData.mailingService.value.ID === 6
            ? configData.apiValueJson
            : Object.fromEntries(
                Object.entries(configData.apiValueJson).filter(
                  ([key]) => key !== "mailgunDomain"
                )
              )
        )
      ) {
        setTestButtonDisabled(false); // enable the button
      } else {
        setTestButtonDisabled(true);
      }
    } else {
      setTestButtonDisabled(true);
    }
  }, [configData]);

  //for customizing the appearance of the React Select to match other input fields
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.selectProps.className?.includes("is-invalid")
        ? "red"
        : inputBorderColor,
      height: "2.8rem",
      fontSize: "0.85rem",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: inputTextColor,
    }),
    menuList: (provided) => ({
      ...provided,
      fontSize: "0.8rem",
    }),
  };

  //handle modal of intruction video
  const handleModal = () => {
    setOpen(true);
  };

  //on change of input fields setting the values
  const handleChange = (e, fromJSON) => {
    const { name, value } = e.target;
    if (fromJSON === "SMTP") {
      if (name === "email") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            smtpValueJson: {
              ...prevState.smtpValueJson,
              [name]: { value: value, isValid: true, errorMsg: "" },
              replyToEmail: { value: value, isValid: true, errorMsg: "" },
            },
          };
        });
      }
      setConfigData((prevState) => {
        return {
          ...prevState,
          smtpValueJson: {
            ...prevState.smtpValueJson,
            [name]: { value: value, isValid: true, errorMsg: "" },
          },
        };
      });
    } else if (fromJSON === "API") {
      if (name === "email") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            apiValueJson: {
              ...prevState.apiValueJson,
              [name]: { value: value, isValid: true, errorMsg: "" },
              replyToEmail: { value: value, isValid: true, errorMsg: "" },
            },
          };
        });
      }
      setConfigData((prevState) => {
        return {
          ...prevState,
          apiValueJson: {
            ...prevState.apiValueJson,
            [name]: { value: value, isValid: true, errorMsg: "" },
          },
        };
      });
    } else {
      if (name === "email") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            [name]: { value: value, isValid: true, errorMsg: "" },
            replyToEmail: { value: value, isValid: true, errorMsg: "" },
          };
        });
      }
      setConfigData((prevState) => {
        return {
          ...prevState,
          [name]: { value: value, isValid: true, errorMsg: "" },
        };
      });
    }
  };

  //on blur setting error if the field is empty
  const handleBlur = (e, fromJSON) => {
    const { name, value } = e.target;
    if (value.trim().length === 0) {
      if (fromJSON === "SMTP") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            smtpValueJson: {
              ...prevState.smtpValueJson,
              [name]: {
                ...prevState.smtpValueJson[name],
                isValid: false,
                errorMsg: "This field is required.",
              },
            },
          };
        });
      } else if (fromJSON === "API") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            apiValueJson: {
              ...prevState.apiValueJson,
              [name]: {
                ...prevState.apiValueJson[name],
                isValid: false,
                errorMsg: "This field is required.",
              },
            },
          };
        });
      } else {
        setConfigData((prevState) => {
          return {
            ...prevState,
            [name]: {
              ...prevState[name],
              isValid: false,
              errorMsg: "This field is required.",
            },
          };
        });
      }
    } else if (
      (name === "email" || name === "replyToEmail") &&
      !emailRegex.test(value)
    ) {
      if (fromJSON === "SMTP") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            smtpValueJson: {
              ...prevState.smtpValueJson,
              [name]: {
                ...prevState.smtpValueJson[name],
                isValid: false,
                errorMsg: "Please enter a valid email",
              },
            },
          };
        });
      } else if (fromJSON === "API") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            apiValueJson: {
              ...prevState.apiValueJson,
              [name]: {
                ...prevState.apiValueJson[name],
                isValid: false,
                errorMsg: "Please enter a valid emai.",
              },
            },
          };
        });
      }
    }
  };

  //on change of react select fields
  const handleSelectChange = (selected, e, fromJSON) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    const formattedDateTime = `${year}${month}${day}-${hours}${minutes}${seconds}`;
    const updatedTitle = `${selected.name} - ${formattedDateTime}`;

    if (fromJSON === "SMTP") {
      //this is for encryptionType is selected
      setConfigData((prevState) => {
        return {
          ...prevState,
          title: { value: updatedTitle, isValid: true, errorMsg: "" },
          smtpValueJson: {
            ...prevState.smtpValueJson,

            [e.name]: {
              value: selected,
              isValid: true,
              errorMsg: "",
            },
            port: {
              value: e.name === "encryptionType" ? selected.port : "",
              isValid: true,
              errorMsg: "",
            },
          },
        };
      });
    } else if (selected.ID === 3) {
      setConfigData((prevState) => {
        return {
          ...prevState,
          title: { value: updatedTitle, isValid: true, errorMsg: "" },
          [e.name]: {
            value: selected,
            isValid: true,
            errorMsg: "",
          },
          smtpValueJson: {
            ...prevState.smtpValueJson,
            smtpServerURL: {
              value: selected.smtpJson.smtpServerURL,
              isValid: true,
              errorMsg: "",
            },
          },
          integrationMethod: { value: "SMTP", isValid: true, errorMsg: "" },
        };
      });
    } else {
      setConfigData((prevState) => {
        return {
          ...prevState,
          title: { value: updatedTitle, isValid: true, errorMsg: "" },
          [e.name]: {
            value: selected,
            isValid: true,
            errorMsg: "",
          },
          smtpValueJson: {
            ...prevState.smtpValueJson,
            smtpServerURL: {
              value: selected.smtpJson.smtpServerURL,
              isValid: true,
              errorMsg: "",
            },
          },
          integrationMethod: { value: "API", isValid: true, errorMsg: "" },
        };
      });
    }
  };

  //on change of react select fields
  const handleSelectOnBlur = (name, value, fromJSON) => {
    if (value.trim().length === 0) {
      if (fromJSON === "SMTP") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            smtpValueJson: {
              ...prevState.smtpValueJson,
              [name]: {
                ...prevState.smtpValueJson[name],
                isValid: false,
                errorMsg: "This Field is Required",
              },
            },
          };
        });
      } else {
        setConfigData((prevState) => {
          return {
            ...prevState,
            [name]: {
              ...prevState[name],
              isValid: false,
              errorMsg: "This Field is Required",
            },
          };
        });
      }
    }
  };

  //to reset the state after form is submitted
  const resetState = () => {
    setConfigData({
      mailingService: {
        value: { ID: 0, name: "" },
        isValid: true,
        errorMsg: "",
      },
      integrationMethod: { value: "SMTP", isValid: true, errorMsg: "" },
      title: { value: "", isValid: true, errorMsg: "" }, //the key name for backend is name
      smtpValueJson: {
        smtpServerURL: {
          value: "smtp.sendgrid.net",
          isValid: true,
          errorMsg: "",
        },
        encryptionType: {
          value: {
            port: "",
            encryptionType: "",
          },
          isValid: true,
          errorMsg: "",
        },
        port: { value: "", isValid: true, errorMsg: "" },
        accessKey: { value: "", isValid: true, errorMsg: "" },
        secretKey: { value: "", isValid: true, errorMsg: "" },
        senderName: { value: "", isValid: true, errorMsg: "" },
        email: { value: "", isValid: true, errorMsg: "" },
        replyToEmail: { value: "", isValid: true, errorMsg: "" },
      },
      apiValueJson: {
        senderName: { value: "", isValid: true, errorMsg: "" },
        email: { value: "", isValid: true, errorMsg: "" },
        replyToEmail: { value: "", isValid: true, errorMsg: "" },
        apiKey: { value: "", isValid: true, errorMsg: "" },
        mailgunDomain: { value: "", isValid: true, errorMsg: "" },
      },
    });
  };

  //on click of test and save connect call to api of add email config
  const handleTestConnection = async () => {
    let configBodyData = {
      mailingServiceID: configData.mailingService.value.ID,
      title: configData.title.value,
      integrationType: configData.integrationMethod.value,
    };
    if (configData.integrationMethod.value === "SMTP") {
      configBodyData = {
        ...configBodyData,
        smtpValueJson: {
          smtpServerURL: configData.smtpValueJson.smtpServerURL.value,
          encryptionType:
            configData.smtpValueJson.encryptionType.value.encryptionType,
          port: configData.smtpValueJson.port.value,
          accessKey: configData.smtpValueJson.accessKey.value,
          secretKey: configData.smtpValueJson.secretKey.value,
          senderName: configData.smtpValueJson.senderName.value,
          email: configData.smtpValueJson.email.value,
          replyToEmail: configData.smtpValueJson.replyToEmail.value,
        },
      };
    } else if (configData.integrationMethod.value === "API") {
      configBodyData = {
        ...configBodyData,
        apiValueJson: {
          senderName: configData.apiValueJson.senderName.value,
          email: configData.apiValueJson.email.value,
          replyToEmail: configData.apiValueJson.replyToEmail.value,
          apiKey: configData.apiValueJson.apiKey.value,
        },
      };
      if (configData.mailingService.value.ID === 6) {
        configBodyData = {
          ...configBodyData,
          apiValueJson: {
            ...configBodyData.apiValueJson,
            mailgunDomain: configData.apiValueJson.mailgunDomain.value,
          },
        };
      }
    }

    const res = await addEmailConfig(configBodyData);

    if (res.code === 200) {
      notify("success", "Integration added successfully!");
      resetState();
      setNewRecordedAdded(true);
      setSelectedMailingService({});
    } else if (res.code === 400) {
      notify(
        "error",
        Array.isArray(res.message)
          ? res.message[0].Msg
          : res.message
          ? res.message
          : "Something went wrong"
      );
    } else {
      notify("error", res.message ? res.message : "Something went wrong");
    }
    setShowLoaderOnClick(false);
  };

  //on click of test and save connect call to api of add email config
  const handleTestConnectionFrmStep1 = async () => {
    let configBodyData = {
      mailingServiceID: configData.mailingService.value.ID,
      title: configData.title.value,
      integrationType: configData.integrationMethod.value,
    };
    if (configData.integrationMethod.value === "SMTP") {
      configBodyData = {
        ...configBodyData,
        smtpValueJson: {
          smtpServerURL: configData.smtpValueJson.smtpServerURL.value,
          encryptionType:
            configData.smtpValueJson.encryptionType.value.encryptionType,
          port: configData.smtpValueJson.port.value,
          accessKey: configData.smtpValueJson.accessKey.value,
          secretKey: configData.smtpValueJson.secretKey.value,
          senderName: configData.smtpValueJson.senderName.value,
          email: configData.smtpValueJson.email.value,
          replyToEmail: configData.smtpValueJson.replyToEmail.value,
        },
      };
    } else if (configData.integrationMethod.value === "API") {
      configBodyData = {
        ...configBodyData,
        apiValueJson: {
          senderName: configData.apiValueJson.senderName.value,
          email: configData.apiValueJson.email.value,
          replyToEmail: configData.apiValueJson.replyToEmail.value,
          apiKey: configData.apiValueJson.apiKey.value,
        },
      };
      if (configData.mailingService.value.ID === 6) {
        configBodyData = {
          ...configBodyData,
          apiValueJson: {
            ...configBodyData.apiValueJson,
            mailgunDomain: configData.apiValueJson.mailgunDomain.value,
          },
        };
      }
    }

    const res = await addEmailConfig(configBodyData);

    if (res.code === 200) {
      modalNotify("success", {
        message: "Integration added successfully!",
        subMessage: "",
      });
      listEmailConfig(dispatch, 1);
      //the parameter true passed to make the state in parent component isAdded true
      toggleAddConfigModal(true);
    } else if (res.code === 400) {
      modalNotify("error", {
        message: Array.isArray(res.message)
          ? res.message[0].Msg
          : res.message
          ? res.message
          : "Something went wrong",
        subMessage: "",
      });
    } else {
      modalNotify("error", {
        message: res.message ? res.message : "Something went wrong",
        subMessage: "",
      });
    }
    setShowLoaderOnClick(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleApiKeyVisibility = () => {
    setShowApiKey(!showApiKey);
  };

  // Save state to Campaign state to localStorage
  const saveCampaignState = (state) => {
    try {
      const existingState = JSON.stringify({
        ...state,
        ...(campaignCode ? { campaignCode: campaignCode } : {}),
      });
      localStorage.setItem("savedCampaignState", existingState);
    } catch (e) {
      console.error("Could not save state", e);
    }
  };

  //seperate function for handle google config from step 1
  const handleGoogleLoginFrmStep1 = () => {
    if (!configData.title.value) {
      return modalNotify("error", {
        message: "Please add title first.",
        subMessage: "",
      });
    }
    const token = JSON.parse(localStorage.getItem("p")).token;
    setIsLoading(true);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("token", `${token}`);
    // Fetch request
    fetch(`${GOOGLE_CONFIG}?title=${configData.title.value}&from=STEP1`, {
      method: "GET",
      headers: headers,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 200) {
          saveCampaignState(createCampaignFields);
          setIsLoading(false);
          window.location.href = res.data;
        } else {
          setIsLoading(false);
          modalNotify("error", {
            message: res.message || "An error occurred during Google login.",
            subMessage: "",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        modalNotify("error", {
          message: "Failed to connect to the Google login service.",
          subMessage: "",
        });
      });
  };

  const handleGoogleLogin = () => {
    if (!configData.title.value) {
      return notify("error", "Please add title first.");
    }
    const token = JSON.parse(localStorage.getItem("p")).token;
    setIsLoading(true);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("token", `${token}`);
    // Fetch request
    fetch(`${GOOGLE_CONFIG}?title=${configData.title.value}`, {
      method: "GET",
      headers: headers,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 200) {
          window.location.href = res.data;
        } else {
          setIsLoading(false);
          notify(
            "error",
            res.message || "An error occurred during Google login."
          );
        }
      })
      .catch((error) => {
        notify("error", "Failed to connect to the Google login service.");
      });
  };

  // Determine number of columns per row based on screen width
  const getColumnsPerRow = () => {
    if (windowWidth >= 1700) return 6;
    if (windowWidth >= 1400) return 3;
    if (windowWidth >= 900) return 3;
    if (windowWidth >= 450) return 2;
    return 1; // Default to 2 per row for mobile screens
  };

  const columnsPerRow = getColumnsPerRow();

  const ReusableAddConfigForm = (
    <Col md={fromCalendar ? "12" : "8"} className="p-0">
      <Row
        className="mb-5 m-0 p-0 d-flex"
        style={{
          gap: columnsPerRow === 6 ? "0.5rem" : "1rem", // Adjust spacing between buttons
        }}
      >
        {mailingServices.map((image, index) => {
          const selectedService = listMailingService.mailingService?.find(
            (service) => service.ID === image.id
          );

          const isSelected =
            selectedService &&
            selectedService.ID === selectedMailingService?.ID;

          const isNewRow = index % columnsPerRow === 0;

          return (
            <React.Fragment key={index}>
              {isNewRow && index !== 0 && <div className="w-100" />}
              <Col
                className="p-1"
                md={12 / columnsPerRow}
                sm={6}
                xs={10}
                lg={12 / columnsPerRow}
                style={{
                  marginBottom: "-1.8rem",
                  flex: `0 0 calc(${100 / columnsPerRow}% - ${
                    columnsPerRow === 6 ? "0.5rem" : "1rem"
                  })`,
                  maxWidth: `calc(${100 / columnsPerRow}% - ${
                    columnsPerRow === 6 ? "0.5rem" : "1rem"
                  })`,
                }}
              >
                <Button
                  className="btn-icon mb-2"
                  color="info"
                  outline
                  type="button"
                  style={{
                    ...styles.optionButton,
                    borderColor: isSelected ? "#19caea" : "gray",
                    width: "100%",
                    boxShadow: isSelected
                      ? "0rem 0rem 0.6rem rgba(0, 0, 0, 0.5)"
                      : "none",
                    borderWidth: isSelected ? "0.2em" : "0.1em",
                  }}
                  onClick={() => {
                    if (selectedService) {
                      setSelectedMailingService(selectedService);
                      handleSelectChange(selectedService, {
                        name: "mailingService",
                      });
                    }
                  }}
                  onMouseOver={(e) => {
                    if (!isSelected) {
                      e.currentTarget.style.boxShadow =
                        "0rem 0rem 0.6rem rgba(0, 0, 0, 0.5)";
                    }
                  }}
                  onMouseOut={(e) => {
                    if (!isSelected) {
                      e.currentTarget.style.boxShadow = "none";
                    }
                  }}
                >
                  <img
                    src={require(`assets/img/brand/${image.name}`)}
                    alt="Mailing Service"
                    style={styles.imgStyle}
                  />

                  {isSelected && <div style={styles.animation} />}
                </Button>
              </Col>
            </React.Fragment>
          );
        })}
      </Row>

      {selectedMailingService?.ID && (
        <div
          className={`border p-3 rounded ${
            windowWidth < 1500 ? "m-0" : "ml-3"
          }`}
          ref={rowRef}
        >
          <FormGroup style={responsiveStyles.formField}>
            <Label
              for="title"
              className={windowWidth < 768 && "w-100"}
              style={styles.label}
            >
              Title (for internal reference){" "}
              <strong style={styles.mandatory}>*</strong>
            </Label>
            <div style={responsiveStyles.inputContainer}>
              <Input
                type="text"
                innerRef={inputRef}
                id="title"
                value={configData.title.value}
                name="title"
                onChange={(e) => {
                  handleChange(e);
                }}
                className={!configData.title.isValid ? "is-invalid" : ""}
                onBlur={(e) => handleBlur(e)}
                style={styles.input}
                placeholder="Enter title"
                maxLength={60}
                autoComplete="off"
              />
              <div style={styles.errorContainer}>
                {!configData.title.isValid && (
                  <small className="text-danger">
                    {configData.title.errorMsg}
                  </small>
                )}
              </div>
            </div>
          </FormGroup>

          <FormGroup style={responsiveStyles.formField}>
            {/* <Label for="mailingService" style={styles.label}>
          Email Service <strong style={styles.mandatory}>*</strong>
        </Label> */}
            <div style={responsiveStyles.inputContainer}>
              {/* <Select
            options={listMailingService.mailingService}
            placeholder="Select Emaling Service"
            name="mailingService"
            getOptionLabel={(options) => options.name}
            getOptionValue={(options) => options.ID}
            onChange={(selected, e) => handleSelectChange(selected, e)}
            onBlur={() => {
              handleSelectOnBlur(
                "mailingService",
                configData.mailingService.value.name
              );
            }}
            styles={customStyles}
            value={
              listMailingService.mailingService?.find(
                (option) => option.ID === configData.mailingService.value.ID
              ) || ""
            } // find the selected option based on value
            isSearchable={true} // enable search functionality
            className={!configData.mailingService.isValid ? "is-invalid" : null}
          /> */}

              <div style={styles.errorContainer}>
                {!configData.mailingService.isValid && (
                  <small className="text-danger">
                    {configData.mailingService.errorMsg}
                  </small>
                )}
              </div>
              {configData.mailingService.value.ID === 4 && (
                <div className="d-flex flex-column">
                  <span>
                    <Button
                      className=" btn-icon text-white mt-3"
                      style={styles.googleButton}
                      onClick={() => {
                        fromCalendar
                          ? handleGoogleLoginFrmStep1()
                          : handleGoogleLogin();
                      }}
                      disabled={fromCalendar ? isLoading : false} //to disable the button in case of when from step1 modal is used and loading is true
                    >
                      <span className="btn-inner--icon bg-white p-2 ml--3">
                        <img
                          alt="Google Icon"
                          src={
                            require("assets/img/icons/common/google.svg")
                              .default
                          }
                        />
                      </span>
                      <span className="btn-inner--text">
                        {fromCalendar && isLoading ? ( //for showing loader on button when on click of button
                          <i className="fa fa-spinner fa-spin fa-lg text-white px-6" />
                        ) : (
                          "Configure with Google"
                        )}
                      </span>
                    </Button>
                  </span>
                </div>
              )}
            </div>
          </FormGroup>
          {/* {configData.mailingService.value.ID === 1 ||
                          configData.mailingService.value.ID === 2 ? (
                            <>
                              <FormGroup style={responsiveStyles.formField}>
                                <Label style={styles.label}>
                                  Integration Method{" "}
                                  <strong style={styles.mandatory}>*</strong>
                                </Label>
                                <div>
                                  <Label check className="ml-4">
                                    <Input
                                      type="radio"
                                      value="API"
                                      name="integrationMethod"
                                      checked={
                                        configData.integrationMethod.value ===
                                        "API"
                                      }
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                    />
                                    API
                                  </Label>
                                  <Label check className="ml-6">
                                    <Input
                                      type="radio"
                                      value="SMTP"
                                      name="integrationMethod"
                                      checked={
                                        configData.integrationMethod.value ===
                                        "SMTP"
                                      }
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                    />
                                    SMTP
                                  </Label>
                                </div>
                              </FormGroup>
                            </>
                          ) : null} */}
          {configData.mailingService.value.ID === 1 ||
          configData.mailingService.value.ID === 3 ||
          configData.mailingService.value.ID === 2 ||
          configData.mailingService.value.ID === 6 ? (
            configData.integrationMethod.value === "SMTP" ? (
              <>
                <FormGroup style={responsiveStyles.formField}>
                  <Label for="smtpServerURL" style={styles.label}>
                    SMTP URL <strong style={styles.mandatory}>*</strong>
                  </Label>
                  <div style={responsiveStyles.inputContainer}>
                    <Input
                      type="text"
                      id="smtpServerURL"
                      className={
                        !configData.smtpValueJson.smtpServerURL.isValid
                          ? "is-invalid"
                          : ""
                      }
                      name="smtpServerURL"
                      value={configData.smtpValueJson.smtpServerURL.value}
                      onChange={(e) => {
                        handleChange(e, "SMTP");
                      }}
                      onBlur={(e) => handleBlur(e, "SMTP")}
                      style={styles.input}
                      placeholder="Enter SMTP URL"
                      // disabled={
                      //   configData.mailingService.value.name ===
                      //   "Custom"
                      //     ? false
                      //     : true
                      // }
                      autoComplete="off"
                    />
                    <div style={styles.errorContainer}>
                      {!configData.smtpValueJson.smtpServerURL.isValid && (
                        <small className="text-danger">
                          {configData.smtpValueJson.smtpServerURL.errorMsg}
                        </small>
                      )}
                    </div>
                  </div>
                </FormGroup>
                <FormGroup style={responsiveStyles.formField}>
                  <Label for="encryptionType" style={styles.label}>
                    Encryption Type <strong style={styles.mandatory}>*</strong>
                  </Label>
                  <div style={responsiveStyles.inputContainer}>
                    <Select
                      options={
                        configData.mailingService.value.encryptionTypeJson
                      }
                      placeholder="Select Encription Type"
                      name="encryptionType"
                      getOptionLabel={(options) => options.encryptionType}
                      getOptionValue={(options) => options.port}
                      onChange={(selected, e) =>
                        handleSelectChange(selected, e, "SMTP")
                      }
                      onBlur={() => {
                        handleSelectOnBlur(
                          "encryptionType",
                          configData.smtpValueJson.encryptionType.value
                            .encryptionType,
                          "SMTP"
                        );
                      }}
                      styles={customStyles}
                      value={
                        configData.smtpValueJson.encryptionType.value
                          .encryptionType
                          ? configData.smtpValueJson.encryptionType.value
                          : ""
                      }
                      className={
                        !configData.smtpValueJson.encryptionType.isValid
                          ? "is-invalid"
                          : null
                      }
                    />
                    <div style={styles.errorContainer}>
                      {!configData.smtpValueJson.encryptionType.isValid && (
                        <small className="text-danger">
                          {configData.smtpValueJson.encryptionType.errorMsg}
                        </small>
                      )}
                    </div>
                  </div>
                </FormGroup>
                <FormGroup style={responsiveStyles.formField}>
                  <Label for="port" style={styles.label}>
                    Port <strong style={styles.mandatory}>*</strong>
                  </Label>
                  <div style={responsiveStyles.inputContainer}>
                    <Input
                      type="text"
                      id="port"
                      className={
                        !configData.smtpValueJson.port.isValid
                          ? "is-invalid"
                          : ""
                      }
                      name="port"
                      value={configData.smtpValueJson.port.value}
                      onChange={(e) => {
                        handleChange(e, "SMTP");
                      }}
                      onBlur={(e) => handleBlur(e, "SMTP")}
                      style={styles.input}
                      placeholder="Enter Port"
                      maxLength={3}
                      disabled={true}
                      autoComplete="off"
                    />
                    <div style={styles.errorContainer}>
                      {!configData.smtpValueJson.port.isValid && (
                        <small className="text-danger">
                          {configData.smtpValueJson.port.errorMsg}
                        </small>
                      )}
                    </div>
                  </div>
                </FormGroup>
                <FormGroup style={responsiveStyles.formField}>
                  <Label for="accessKey" style={styles.label}>
                    Access Key / Username{" "}
                    <strong style={styles.mandatory}>*</strong>
                  </Label>
                  <div style={responsiveStyles.inputContainer}>
                    <Input
                      type="text"
                      id="accessKey"
                      className={
                        !configData.smtpValueJson.accessKey.isValid
                          ? "is-invalid"
                          : ""
                      }
                      name="accessKey"
                      value={configData.smtpValueJson.accessKey.value}
                      onChange={(e) => {
                        handleChange(e, "SMTP");
                      }}
                      onBlur={(e) => handleBlur(e, "SMTP")}
                      style={styles.input}
                      placeholder="Enter access key or username"
                      maxLength={100}
                      autoComplete="off"
                    />
                    <div style={styles.errorContainer}>
                      {!configData.smtpValueJson.accessKey.isValid && (
                        <small className="text-danger">
                          {configData.smtpValueJson.accessKey.errorMsg}
                        </small>
                      )}
                    </div>
                  </div>
                </FormGroup>
                <FormGroup style={responsiveStyles.formField}>
                  <Label for="secretKey" style={styles.label}>
                    Secret Key / Password{" "}
                    <strong style={styles.mandatory}>*</strong>
                  </Label>
                  <div style={responsiveStyles.inputContainer}>
                    <InputGroup>
                      <Input
                        type={showPassword ? "text" : "password"}
                        className={
                          !configData.smtpValueJson.secretKey.isValid
                            ? "is-invalid"
                            : ""
                        }
                        id="secretKey"
                        name="secretKey"
                        value={configData.smtpValueJson.secretKey.value}
                        onChange={(e) => handleChange(e, "SMTP")}
                        onBlur={(e) => handleBlur(e, "SMTP")}
                        style={styles.input}
                        placeholder="Enter secret key or password"
                        maxLength={200}
                        autoComplete="off"
                      />
                      <InputGroupText
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          border:
                            !configData.smtpValueJson.secretKey.isValid &&
                            "0.1rem solid #fb6340",
                          borderLeft:
                            !configData.smtpValueJson.secretKey.isValid &&
                            "none",
                        }}
                      >
                        <InputGroupAddon addonType="append">
                          <i
                            onClick={togglePasswordVisibility}
                            className={
                              showPassword
                                ? "fa fa-eye password-icon"
                                : "fa fa-eye-slash password-icon"
                            }
                          />
                        </InputGroupAddon>
                      </InputGroupText>
                    </InputGroup>
                    <div style={styles.errorContainer}>
                      {!configData.smtpValueJson.secretKey.isValid && (
                        <small className="text-danger">
                          {configData.smtpValueJson.secretKey.errorMsg}
                        </small>
                      )}
                    </div>
                  </div>
                </FormGroup>
              </>
            ) : configData.integrationMethod.value === "API" ? (
              <FormGroup style={responsiveStyles.formField}>
                <Label for="apiKey" style={styles.label}>
                  API key <strong style={styles.mandatory}>*</strong>
                </Label>
                <div style={responsiveStyles.inputContainer}>
                  <InputGroup>
                    <Input
                      type={showApiKey ? "text" : "password"}
                      className={
                        !configData.apiValueJson.apiKey.isValid
                          ? "is-invalid"
                          : ""
                      }
                      id="apiKey"
                      name="apiKey"
                      value={configData.apiValueJson.apiKey.value}
                      onChange={(e) => handleChange(e, "API")}
                      onBlur={(e) => handleBlur(e, "API")}
                      style={styles.input}
                      placeholder="Enter API key"
                      autoComplete="off"
                    />
                    <InputGroupText
                      style={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        border:
                          !configData.apiValueJson.apiKey.isValid &&
                          "0.1rem solid #fb6340",
                        borderLeft:
                          !configData.apiValueJson.apiKey.isValid && "none",
                      }}
                    >
                      <InputGroupAddon addonType="append">
                        <i
                          onClick={toggleApiKeyVisibility}
                          className={
                            showApiKey
                              ? "fa fa-eye password-icon"
                              : "fa fa-eye-slash password-icon"
                          }
                        />
                      </InputGroupAddon>
                    </InputGroupText>
                  </InputGroup>
                  <div style={styles.errorContainer}>
                    {!configData.apiValueJson.apiKey.isValid && (
                      <small className="text-danger">
                        {configData.apiValueJson.apiKey.errorMsg}
                      </small>
                    )}
                  </div>
                </div>
              </FormGroup>
            ) : null
          ) : null}
          {configData.mailingService.value.ID === 6 && (
            <FormGroup style={responsiveStyles.formField}>
              <Label for="mailgunDomain" style={styles.label}>
                Mailgun Domain <strong style={styles.mandatory}>*</strong>
              </Label>
              <div style={responsiveStyles.inputContainer}>
                <Input
                  type="text"
                  id="mailgunDomain"
                  className={
                    !configData.apiValueJson.mailgunDomain.isValid
                      ? "is-invalid"
                      : ""
                  }
                  value={configData.apiValueJson.mailgunDomain.value}
                  name="mailgunDomain"
                  onChange={(e) =>
                    handleChange(e, configData.integrationMethod.value)
                  }
                  onBlur={(e) =>
                    handleBlur(e, configData.integrationMethod.value)
                  }
                  style={styles.input}
                  placeholder="Enter mailgun domain"
                  maxLength={100}
                  autoComplete="off"
                />
                <div style={styles.errorContainer}>
                  {!configData.apiValueJson.mailgunDomain.isValid && (
                    <small className="text-danger">
                      {configData.apiValueJson.mailgunDomain.errorMsg}
                    </small>
                  )}
                </div>
              </div>
            </FormGroup>
          )}
          {configData.mailingService.value.name !== "" &&
            configData.mailingService.value.ID !== 4 &&
            configData.integrationMethod.value !== "" && (
              <>
                <FormGroup style={responsiveStyles.formField}>
                  <Label for="senderName" style={styles.label}>
                    Sender Name <strong style={styles.mandatory}>*</strong>
                  </Label>
                  <div style={responsiveStyles.inputContainer}>
                    <Input
                      type="text"
                      id="senderName"
                      className={
                        !configData[
                          configData.integrationMethod.value === "SMTP"
                            ? "smtpValueJson"
                            : "apiValueJson"
                        ].senderName.isValid
                          ? "is-invalid"
                          : ""
                      }
                      value={
                        configData[
                          configData.integrationMethod.value === "SMTP"
                            ? "smtpValueJson"
                            : "apiValueJson"
                        ].senderName.value
                      }
                      name="senderName"
                      onChange={(e) =>
                        handleChange(e, configData.integrationMethod.value)
                      }
                      onBlur={(e) =>
                        handleBlur(e, configData.integrationMethod.value)
                      }
                      style={styles.input}
                      placeholder="Enter sender name"
                      maxLength={60}
                      autoComplete="off"
                    />
                    <div style={styles.errorContainer}>
                      {!configData[
                        configData.integrationMethod.value === "SMTP"
                          ? "smtpValueJson"
                          : "apiValueJson"
                      ].senderName.isValid && (
                        <small className="text-danger">
                          {
                            configData[
                              configData.integrationMethod.value === "SMTP"
                                ? "smtpValueJson"
                                : "apiValueJson"
                            ].senderName.errorMsg
                          }
                        </small>
                      )}
                    </div>
                  </div>
                </FormGroup>
                <FormGroup style={responsiveStyles.formField}>
                  <Label for="email" style={styles.label}>
                    Email <strong style={styles.mandatory}>*</strong>
                  </Label>
                  <div style={responsiveStyles.inputContainer}>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      className={
                        !configData[
                          configData.integrationMethod.value === "SMTP"
                            ? "smtpValueJson"
                            : "apiValueJson"
                        ].email.isValid
                          ? "is-invalid"
                          : ""
                      }
                      value={
                        configData[
                          configData.integrationMethod.value === "SMTP"
                            ? "smtpValueJson"
                            : "apiValueJson"
                        ].email.value
                      }
                      onChange={(e) =>
                        handleChange(e, configData.integrationMethod.value)
                      }
                      onBlur={(e) =>
                        handleBlur(e, configData.integrationMethod.value)
                      }
                      style={styles.input}
                      placeholder="Enter email"
                      maxLength={100}
                      autoComplete="off"
                    />
                    <div style={styles.errorContainer}>
                      {!configData[
                        configData.integrationMethod.value === "SMTP"
                          ? "smtpValueJson"
                          : "apiValueJson"
                      ].email.isValid && (
                        <small className="text-danger">
                          {
                            configData[
                              configData.integrationMethod.value === "SMTP"
                                ? "smtpValueJson"
                                : "apiValueJson"
                            ].email.errorMsg
                          }
                        </small>
                      )}
                    </div>
                  </div>
                </FormGroup>
                <FormGroup style={responsiveStyles.formField}>
                  <Label for="replyToEmail" style={styles.label}>
                    Reply-to Email <strong style={styles.mandatory}>*</strong>
                  </Label>
                  <div style={responsiveStyles.inputContainer}>
                    <Input
                      type="email"
                      id="replyToEmail"
                      name="replyToEmail"
                      className={
                        !configData[
                          configData.integrationMethod.value === "SMTP"
                            ? "smtpValueJson"
                            : "apiValueJson"
                        ].replyToEmail.isValid
                          ? "is-invalid"
                          : ""
                      }
                      value={
                        configData[
                          configData.integrationMethod.value === "SMTP"
                            ? "smtpValueJson"
                            : "apiValueJson"
                        ].replyToEmail.value
                      }
                      onChange={(e) =>
                        handleChange(e, configData.integrationMethod.value)
                      }
                      onBlur={(e) =>
                        handleBlur(e, configData.integrationMethod.value)
                      }
                      style={styles.input}
                      placeholder="Enter reply-to email"
                      maxLength={100}
                      autoComplete="off"
                    />
                    <div style={styles.errorContainer}>
                      {!configData[
                        configData.integrationMethod.value === "SMTP"
                          ? "smtpValueJson"
                          : "apiValueJson"
                      ].replyToEmail.isValid && (
                        <small className="text-danger">
                          {
                            configData[
                              configData.integrationMethod.value === "SMTP"
                                ? "smtpValueJson"
                                : "apiValueJson"
                            ].replyToEmail.errorMsg
                          }
                        </small>
                      )}
                    </div>
                  </div>
                </FormGroup>
              </>
            )}
          <div className="d-flex justify-content-end mb-2">
            {configData.mailingService.value.ID !== 4 &&
              selectedMailingService?.ID && (
                <Button
                  color={testButtonDisabled ? "default" : "info"}
                  disabled={testButtonDisabled || showLoaderOnClick}
                  onClick={() => {
                    setShowLoaderOnClick(true);
                    if (fromCalendar) {
                      handleTestConnectionFrmStep1();
                    } else {
                      handleTestConnection();
                    }
                  }}
                  md={3}
                  sm={6}
                >
                  {showLoaderOnClick ? (
                    <i
                      className="fa fa-spinner fa-spin fa-lg"
                      style={{ color: "white" }}
                    />
                  ) : (
                    "Test Connection & Save"
                  )}
                </Button>
              )}
          </div>
        </div>
      )}
      <h3
        style={{
          ...styles.noteTextStyle,
          marginLeft: windowWidth > 1500 ? "1rem" : "0.5rem", // Adjust value as needed
        }}
      >
        <span>
          <i className="fa-solid fa-lock mr-1 text-green"></i>
        </span>
        The Integrations are securely stored using 256-bit AES-GCM encryption.
      </h3>
    </Col>
  );

  return fromCalendar ? (
    ReusableAddConfigForm
  ) : (
    <>
      <Warning />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full viewport height
            backgroundColor: "white", // Blank screen background color
          }}
        >
          <i className="fa fa-spinner fa-spin fa-3x text-black" />
        </div>
      ) : (
        <>
          <div className="rna-wrapper">
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          {/* <SimpleHeader name="Settings" parentName="Settings" /> */}
          <Container className="mt--6" fluid>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0">Add Integration</h3>
                    <medium>
                      By adding an Integration, you will be able to send a large
                      number of calendar invites through that connection.
                    </medium>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row>
                        {ReusableAddConfigForm}

                        {
                          <Col md="4">
                            {configData.mailingService.value.name !== "" &&
                              configData.integrationMethod.value !== "" && (
                                <>
                                  <div
                                    style={{
                                      ...styles.infoContainer, // Spread existing styles
                                      marginTop: windowWidth < 768 && "2rem", // Conditional styling
                                    }}
                                  >
                                    <h5 style={styles.heading}>
                                      {/* Mailing_Service_Name Instructions */}
                                      {
                                        mailingServiceName[
                                          configData.mailingService.value.ID
                                        ]
                                      }{" "}
                                      Instructions :
                                    </h5>
                                    <p style={styles.contain}>
                                      {/* service information */}
                                      {
                                        serviceInfo[
                                          configData.mailingService.value.ID
                                        ]
                                      }
                                    </p>
                                    {/* Show subtext only for SendGrid and Brevo  */}
                                    {(configData.mailingService.value.ID ===
                                      1 ||
                                      configData.mailingService.value.ID == 2 ||
                                      configData.mailingService.value.ID ==
                                        6) && (
                                      <>
                                        {/* subtext */}
                                        {/* <p style={styles.contain}>
                                      {
                                        serviceSubText[
                                          configData.mailingService.value.ID
                                        ]
                                      }
                                    </p> */}
                                        {/* youtube button */}
                                        {/* <div
                                      className="text-center"
                                      style={styles.contain}
                                    >
                                      <Button
                                        className="btn-icon"
                                        color="error"
                                        outline
                                        type="button"
                                        onClick={handleModal}
                                      >
                                        <span className="btn-inner--icon">
                                          <i className="fab fa-youtube fa-lg" />
                                        </span>
                                        <span className="btn-inner--text">
                                          {`${
                                            mailingServiceName[
                                              configData.mailingService.value.ID
                                            ]
                                          } Instructions`}
                                        </span>
                                      </Button>
                                    </div> */}
                                        {/* website link */}
                                        <p style={styles.contain}>
                                          <a
                                            href={
                                              mailingServiceWeb[
                                                configData.mailingService.value
                                                  .ID
                                              ]
                                            }
                                            target="_blank"
                                          >
                                            {
                                              mailingServiceName[
                                                configData.mailingService.value
                                                  .ID
                                              ]
                                            }{" "}
                                            Website Link
                                          </a>
                                          {/* <a
                                        href={
                                          mailingServiceWeb[
                                            configData.mailingService.value.ID
                                          ]
                                        }
                                        target="_blank"
                                      >
                                        {
                                          mailingServiceWeb[
                                            configData.mailingService.value.ID
                                          ]
                                        }
                                      </a> */}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                  {/* Note to show for All Mailign service*/}

                                  <div
                                    style={styles.infoContainer}
                                    className="mt-3"
                                  >
                                    <h5 style={styles.heading}>Note</h5>
                                    <p style={styles.contain}>
                                      {
                                        serviceNote[
                                          configData.mailingService.value.ID
                                        ]
                                      }
                                    </p>
                                  </div>
                                </>
                              )}
                          </Col>
                        }
                      </Row>
                    </Form>
                    <hr />
                    <div ref={configListRef}>
                      <ConfigTable
                        setNewRecordedAdded={setNewRecordedAdded}
                        newRecordAdded={newRecordAdded}
                        scrollList={scrollList}
                        configListRef={configListRef}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Modal
                isOpen={open}
                toggle={() => {
                  setOpen(false);
                }}
                style={styles.videoContainer}
                className="d-flex justify-content-center"
              >
                {mailVideo[configData.mailingService.value.ID]}
              </Modal>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default EmailConfiguration;

const styles = {
  optionButton: {
    position: "relative",
    overflow: "hidden",
    padding: "0.5rem",
    height: "4rem",
    backgroundColor: "transparent",
    transition: "box-shadow 0.3s ease-in-out, filter 0.3s ease-in-out",
  },
  imgStyle: {
    width: "auto",
    height: "100%",
    maxWidth: "100%",
    // objectFit: "contain",
  },
  animation: {
    position: "absolute",
    bottom: "0",
    left: "50%",
    width: "0",
    height: "0.1em",
    backgroundColor: "#19caea",
    animation: "lineAnimation 1s forwards ease-in-out",
  },
  formInstructions: {
    paddingRight: "15px",
  },

  label: {
    width: "200px",
    textAlign: "left",
    marginRight: "1rem",
  },
  input: {
    flex: 1,
  },
  videoContainer: {
    width: "70%", // Adjust the width as needed
    maxWidth: "1200px", // Set a maximum width
    height: "80vh", // Adjust the height as needed
    maxHeight: "800px", // Set a maximum height
  },
  infoContainer: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    backgroundColor: "#fff",
    // White background for container
  },
  heading: {
    backgroundColor: "#f6f9fc", // Background color for note content
    padding: "10px", // Adjust padding for better visual separation
    borderRadius: "4px", // Optional: Add border radius for rounded corners
  },
  contain: {
    padding: "8px",
    margin: 0,
  },
  underline: {
    border: "0",
    borderTop: "1px solid #ddd", // Color of the underline
    margin: "1px 0", // Space around the underline
  },
  highlight: {
    color: "blue", // Blue color for "Click here"
    textDecoration: "underline", // Optional: Adds underline for emphasis
    cursor: "pointer", // Optional: Changes cursor to pointer for a link-like effect
  },
  errorContainer: {
    color: "red", // Optional: Adjust color of the error message
  },
  mandatory: {
    color: "red",
    fontSize: "0.875rem",
  },
  noteTextStyle: {
    marginBottom: "-15px",
    paddingTop: "20px",
    width: "100%",
    fontSize: "0.875rem",
    color: "#6c757d",
  },
  googleButton: {
    backgroundColor: "#00639b",
  },
};
