import {
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { sendTestInviteEmail } from "action/campaign/createCampaign";
import { attendeeParameter, labelToKeyMap } from "../Common/constants";
import { mapData } from "../Common/constants";
import { emailRegex } from "components/CommonFunctions";
import { showInProgressAlert } from "components/CommonFunctions";
import { CAMPAIGN_STATUS_IN_PROGRESS } from "components/Common/constants";
import { replaceQuillClassesWithInlineStyles } from "components/CommonFunctions";

function PreviewModal(props) {
  const [showTestSendEmail, setShowTestSendEmail] = useState(false);
  const [emailSendFields, setEmailSendFields] = useState({
    firstName: { value: "", isValid: true },
    email: { value: "", isValid: true },
    lastName: { value: "", isValid: true },
    field1: { value: "", isValid: true },
    field2: { value: "", isValid: true },
    field3: { value: "", isValid: true },
    field4: { value: "", isValid: true },
    field5: { value: "", isValid: true },
  });
  const [showLoader, setShowLoader] = useState(false);

  const showParams = attendeeParameter.filter((item) => {
    const key = labelToKeyMap[item.label]; // Get the correct key for usedDynamicParams
    return props.usedDynamicParams[key]; // Only keep items where the corresponding key is true
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const clearData = () => {
    setEmailSendFields({
      firstName: { value: "", isValid: true },
      email: { value: "", isValid: true },
      lastName: { value: "", isValid: true },
      field1: { value: "", isValid: true },
      field2: { value: "", isValid: true },
      field3: { value: "", isValid: true },
      field4: { value: "", isValid: true },
      field5: { value: "", isValid: true },
    });
  };
  //in case of subject to replace again found any parameter other wise retunr only the actual value
  function reReplace(str) {
    const dynamicParmeter = [
      "{first-name}",
      "{last-name}",
      "{attendee-email}",
      "{email-subject}",
      "{event-start-datetime}",
      "{event-end-datetime}",
      "{event-meet-url}",
      "{event-location}",
      "{field1}",
      "{field2}",
      "{field3}",
      "{field4}",
      "{field5}",
      "{unsubscribe-link}",
      "{sent-via-bulkcalendar-link}",
    ];
    // Placeholder mapping
    const replacements = {
      "{first-name}": "John",
      "{last-name}": "Smith",
      "{attendee-email}": "johnsmith@gmail.com",
      "{event-start-datetime}": props.createCampaignFields.startDate.value,
      "{event-end-datetime}": props.createCampaignFields.endDate.value,
      "{event-meet-url}": props.createCampaignFields.eventTypeText.value,
      "{event-location}": props.createCampaignFields.eventTypeText.value,
      "{field1}": "Field1_value_from_csv",
      "{field2}": "Field2_value_from_csv",
      "{field3}": "Field3_value_from_csv",
      "{field4}": "Field4_value_from_csv",
      "{field5}": "Field5_value_from_csv",
    };

    // Base case: If no dynamic parameters are found, return the string
    const foundPlaceholder = dynamicParmeter.find((param) =>
      str?.includes(param)
    );
    if (!foundPlaceholder) {
      return str; // No placeholders found, return the final string
    }
    // Recursive case: Replace the found placeholder and recurse
    const replacedString = str.replace(
      foundPlaceholder,
      replacements[foundPlaceholder] || ""
    );
    // Call the function recursively until no more placeholders are found
    return reReplace(replacedString);
  }

  const handleShowInputFields = ({ label, field, maxLength, errMsg }) => {
    return (
      props.usedDynamicParams?.[field] && (
        <Col md={6} key={label}>
          <FormGroup>
            <label className="form-control-label">
              {label}
              <strong style={{ color: "red" }}> *</strong>
            </label>
            <Input
              placeholder={label}
              type="text"
              name={field}
              maxLength={maxLength}
              value={emailSendFields?.[field].value}
              onChange={(e) =>
                handleChangeSendEmail(e.target.name, e.target.value)
              }
              onBlur={(e) => onBlurSendEmail(e.target.name, e.target.value)}
              className={emailSendFields?.[field].isValid ? "" : "is-invalid"}
              autoComplete="off"
            />
            {!emailSendFields?.[field]?.isValid && (
              <div className="invalid-feedback">{errMsg}</div>
            )}
          </FormGroup>
        </Col>
      )
    );
  };

  const getReplacedValue = (text) => {
    // Placeholder mapping
    const replacements = {
      "{first-name}": "John",
      "{last-name}": "Smith",
      "{attendee-email}": "johnsmith@gmail.com",
      "{email-subject}": reReplace(
        props.createCampaignFields.emailSubject.value
      ),
      "{event-start-datetime}": props.createCampaignFields.startDate.value,
      "{event-end-datetime}": props.createCampaignFields.endDate.value,
      "{event-meet-url}": props.createCampaignFields.eventTypeText.value,
      "{event-location}": props.createCampaignFields.eventTypeText.value,
      "{field1}": "Field1_value_from_csv",
      "{field2}": "Field2_value_from_csv",
      "{field3}": "Field3_value_from_csv",
      "{field4}": "Field4_value_from_csv",
      "{field5}": "Field5_value_from_csv",
      "{unsubscribe-link}": "<a href='#'>Unsubscribe</a>",
      "{sent-via-bulkcalendar-link}": `<a target="_blank" href='https://www.bulkcalendar.com/'>Sent via BulkCalendar.com</a>`,
    };

    // Replace placeholders using a regular expression
    //The regular expression /\{[^\}]+\}/g matches any text within curly braces {}.
    //The callback function checks if the matched placeholder exists in the replacements map
    // and replaces it accordingly. If no replacement is found, the original placeholder is returned.
    return text?.replace(
      /\{[^\}]+\}/g,
      (match) => replacements[match] || match
    );
  };

  const handleChangeSendEmail = (name, value) => {
    setEmailSendFields((prevState) => {
      return {
        ...prevState,
        [name]: { value: value, isValid: true },
      };
    });
  };

  const onBlurSendEmail = (name, value) => {
    // Trim the value initially to handle extra spaces
    const trimmedValue = value.trim();

    if (name === "email") {
      if (!emailRegex.test(trimmedValue)) {
        setEmailSendFields((prevState) => {
          return {
            ...prevState,
            [name]: { ...prevState[name], isValid: false },
          };
        });
      } else {
        // Added this new else to save the valid email but which have extra spaces else it gives not valid error
        setEmailSendFields((prevState) => {
          return {
            ...prevState,
            [name]: { ...prevState[name], value: trimmedValue, isValid: true }, // Save trimmed and valid email
          };
        });
      }
    } else if (
      (name === "firstName" || name === "lastName") &&
      (!/^[a-zA-Z ]*$/.test(trimmedValue) || trimmedValue === "")
    ) {
      setEmailSendFields((prevState) => {
        return {
          ...prevState,
          [name]: { ...prevState[name], isValid: false },
        };
      });
    } else if (value.trim().length === 0) {
      setEmailSendFields((prevState) => {
        return {
          ...prevState,
          [name]: { ...prevState[name], isValid: false },
        };
      });
    } else {
      setEmailSendFields((prevState) => {
        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            value: trimmedValue,
            isValid: true,
          }, // Save valid first/last name
        };
      });
    }
  };

  //send test calendar invite
  const handleSendTestEmail = async () => {
    let bodyData = {
      email: emailSendFields.email.value,
    };
    //Mapping the data into bodyData which is not empty
    mapData.forEach((item) => {
      if (emailSendFields?.[item.name]?.value !== "")
        if (item.name !== "email") {
          bodyData[item.name] = emailSendFields?.[item.name]?.value;
        }
    });
    const res = await sendTestInviteEmail(props.campaignCode, bodyData);
    if (res.code === 200) {
      props.notify("success", {
        message: "Test Calendar Invite Sent Successfully",
        subMessage: "",
      });
      //to close the form after 2 seconds
      setTimeout(() => {
        setShowTestSendEmail(false);
        clearData();
      }, 2000);
    } else {
      props.notify("error", {
        message: "Email Sent failed!",
        subMessage: res.message || "Something went wrong.",
      });
    }
    setShowLoader(false);
  };

  const checkBtnDisable = () => {
    for (const key in props.usedDynamicParams) {
      if (
        (props.usedDynamicParams?.[key] &&
          emailSendFields?.[key].value === "") ||
        !emailSendFields?.[key].isValid
      )
        return true;
    }
    return false;
  };

  //on click of Send Test Calendar Invite
  const handleTestInviteClick = () => {
    //condition checked that if calendar is in progress so to restrict further action
    if (props.campaignStatus === CAMPAIGN_STATUS_IN_PROGRESS) {
      return showInProgressAlert();
    }
    setShowTestSendEmail(!showTestSendEmail);
  };

  return (
    <Modal
      isOpen={props.modal}
      style={styles.modal}
      // scrollable
      size="xl"
    >
      <ModalHeader toggle={props.toggleModal} className="pb-0 text-h3">
        Create Calendar Step 1
      </ModalHeader>
      <ModalBody className="pt-0 ">
        <Row className="p-3">
          <Col md={7} style={windowWidth > 768 ? styles.colScroll : {}}>
            <Row>
              <div className=" d-flex " style={{ columnGap: 10 }}>
                <label className="label-head">Calendar Name:</label>
                <p>{props.createCampaignFields.campaignName.value}</p>
              </div>
            </Row>
            <Row className="mt-2">
              <Col className="p-0">
                <label className="label-head">Calendar Title:</label>
                <p>
                  {getReplacedValue(
                    props.createCampaignFields.calendarTitle.value
                  )}
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex p-0">
                <label className="label-head">
                  Send auto-accepted calendar invites:
                </label>
                <p className="button ml-2">
                  {props.createCampaignFields?.bookCalendar.value === "YES"
                    ? "Yes"
                    : "No"}
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="p-0">
                <label className="label-head">Calendar Description:</label>
                <div className="p-2 mr-2" style={styles.descriptionContainer}>
                  {/* check if the incoming string have <p> in the string if have 
                    then considereing it as html and handled in such a way to show the html content properly */}
                  {props.createCampaignFields.calenderDescription.value.includes(
                    "<p>"
                  ) ||
                  props.createCampaignFields.calenderDescription.value.includes(
                    "<div>"
                  ) ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: getReplacedValue(
                          props.createCampaignFields.calenderDescription.value
                        ),
                      }}
                    />
                  ) : (
                    // whiteSpace: "pre-line" Allows '\n' in text to render as line breaks in HTML.
                    <p style={{ whiteSpace: "pre-line" }}>
                      {getReplacedValue(
                        props.createCampaignFields.calenderDescription.value
                      )}
                    </p>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <div className=" d-flex " style={{ columnGap: 10 }}>
                <label className="label-head">
                  <i className="fa-regular fa-clock"></i> Time Zone:
                </label>
                <p>{props.createCampaignFields.timeZone.value?.location}</p>
              </div>
            </Row>
            <Row>
              <Col md="6" className="p-0">
                <div className=" d-flex " style={{ columnGap: 10 }}>
                  <label className="label-head">
                    <i className="fa-regular fa-calendar"></i> Start Date:
                  </label>{" "}
                  <p className="mt-0">
                    {props.createCampaignFields.startDate.value}
                  </p>
                </div>
              </Col>
              <Col md="6" className="p-0">
                <div className=" d-flex " style={{ columnGap: 10 }}>
                  <label className="label-head">
                    <i className="fa-regular fa-calendar"></i> End Date:{" "}
                  </label>
                  <p className="mt-0">
                    {props.createCampaignFields.endDate.value}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6" className="p-0">
                <div className=" d-flex" style={{ columnGap: 10 }}>
                  <label className="label-head">
                    <i className="fa-solid fa-user"></i> Organizer Name:
                  </label>
                  <p>{props.createCampaignFields.organizerName.value}</p>
                </div>
              </Col>
              <Col md="6" className="p-0">
                <div className="d-flex" style={{ columnGap: 10 }}>
                  <label className="label-head">
                    <i className="fa-regular fa-envelope"></i> Organizer Email:
                  </label>
                  <p className="text-break">
                    {props.createCampaignFields.organizerEmail.value}
                  </p>
                </div>
              </Col>
            </Row>
            {/* <Row className="mt-2 mb-0">
              <Col md="auto" className="border-right">
                <p className="mt-0">
                  <i className="fa-regular fa-calendar"></i> Start Date:{" "}
                  {formatDateWithTime(
                    props.createCampaignFields.startDate.value
                  )}
                </p>
              </Col>
              <Col md="auto" className="border-right">
                <p className="mt-0">
                  <i className="fa-regular fa-calendar"></i> End Date:{" "}
                  {formatDateWithTime(props.createCampaignFields.endDate.value)}
                </p>
              </Col>
            </Row> */}
            <Row>
              <Col md="6" className="p-0">
                <div className=" d-flex" style={{ columnGap: 10 }}>
                  <label className="label-head">Integration:</label>
                  <p className="text-break">
                    {props.createCampaignFields.senderEmail.value.Title}
                  </p>
                </div>
              </Col>
              <Col md="6" className="p-0">
                <div className="d-flex" style={{ columnGap: 10 }}>
                  <label className="label-head">
                    <i className="fa-thin fa-calendar-star"></i> Event Type:
                  </label>
                  <p>
                    {props.createCampaignFields.eventType.value.value ===
                    "ONLINE"
                      ? "Online"
                      : "Offline"}
                  </p>
                </div>
              </Col>
            </Row>
            {/* <Col className="p-0 mt-3">
              <div className=" d-flex" style={{ columnGap: 10 }}>
                <label className="label-head">Integration:</label>
                <p>{props.createCampaignFields.senderEmail.value.Title}</p>
              </div>
            </Col>
            <Col className="p-0">
              <div className="d-flex" style={{ columnGap: 10 }}>
                <label className="label-head"> Event Type:</label>
                <p>
                  {props.createCampaignFields.eventType.value.value === "ONLINE"
                    ? "Online"
                    : "Offline"}
                </p>
              </div>
            </Col> */}
            {props.createCampaignFields.eventTypeText.value.trim().length >
              0 && (
              <Row>
                <Col className="p-0">
                  <label className="label-head">
                    {props.createCampaignFields.eventType.value.value ===
                    "ONLINE" ? (
                      "Event Meet URL:"
                    ) : (
                      <>
                        <i className="fa-solid fa-location-dot"></i> Event
                        Location:
                      </>
                    )}
                  </label>
                  <p>{props.createCampaignFields.eventTypeText.value}</p>
                </Col>
              </Row>
            )}
            {/* <Col className="p-0 ">
              <label className="label-head">Calendar Title:</label>
              <p>{props.createCampaignFields.calendarTitle.value}</p>
            </Col>
            <Col className="p-0">
              <label className="label-head">Calendar Description:</label>
              <div className="p-2" style={styles.descriptionContainer}>
                <p>
                  {getReplacedValue(
                    props.createCampaignFields.calenderDescription.value
                  )}
                </p>
              </div>
            </Col> */}
            <hr className="p-0 mt-2 mb-2" />
            <Row>
              <Col className="p-0 ">
                <label className="label-head">Email Subject:</label>
                <p>
                  {getReplacedValue(
                    props.createCampaignFields.emailSubject.value
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="p-0">
                <label className="label-head">Email Body:</label>
                <div className="p-2 mr-2" style={styles.descriptionContainer}>
                  {/* Render HTML content with dangerouslySetInnerHTML */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: replaceQuillClassesWithInlineStyles(
                        // Added this function to convert the quill's o/p to a o/p having all inline css and div tags
                        getReplacedValue(
                          props.createCampaignFields.emailBody.value
                        )
                      ),
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            md={5}
            className={` ${
              windowWidth < 768 ? "border-top mt-4 p-0" : "border-left"
            }`}
          >
            {!showTestSendEmail && (
              <Row
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "2rem",
                  marginTop: "0.5rem",
                  paddingInline: "1rem",
                  // paddingRight:"0.2rem"
                }}
              >
                <Button
                  style={{
                    height: "fit-content",
                    width: windowWidth < 768 ? "100%" : "fit-content",
                  }}
                  className="btn-icon"
                  outline
                  type="button"
                  color="success"
                  onClick={(e) => handleTestInviteClick()}
                  size="md"
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-email-83" />
                  </span>
                  <span className="btn-inner--text">
                    Send Test Calendar Invite
                  </span>
                </Button>
              </Row>
            )}
            {showTestSendEmail && (
              <Card
                className={`rounded border ${windowWidth < 768 ? "mt-3" : ""}`}
              >
                <CardHeader className="pb-0 px-3">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      paddingBottom: "0.2rem",
                    }}
                  >
                    <p className="h4">Send Test Calendar Invite</p>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowTestSendEmail(!showTestSendEmail)}
                    >
                      <i className="fa-solid fa-xmark fa-xs"></i>
                    </div>
                  </div>

                  <p className="m-0" style={{ fontSize: "0.9rem" }}>
                    1 calendar invite will be used to send a test invite.
                  </p>
                </CardHeader>
                <CardBody className="px-3">
                  <Row lg="12" md="12" sm="12">
                    {mapData?.map((item) => {
                      return handleShowInputFields({
                        label: item.label,
                        field: item.name,
                        maxLength: item.maxLength,
                        errMsg: item.errMsg,
                      });
                    })}
                  </Row>
                  <Row>
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        color="info"
                        className="mt-1"
                        onClick={() => {
                          //handleNext();
                          setShowLoader(true);
                          handleSendTestEmail();
                        }}
                        size="md"
                        disabled={checkBtnDisable()}
                      >
                        {showLoader ? (
                          <i className="fa fa-spinner fa-spin fa-lg text-white" />
                        ) : (
                          "Send"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>

                {/* <CardBody>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">
                          Attendee Email
                          <strong style={{ color: "red" }}> *</strong>
                        </label>
                        <Input
                          placeholder="Attendee Email"
                          type="text"
                          name="email"
                          maxLength={100}
                          value={emailSendFields.email.value}
                          onChange={(e) =>
                            handleChangeSendEmail(e.target.name, e.target.value)
                          }
                          onBlur={(e) =>
                            onBlurSendEmail(e.target.name, e.target.value)
                          }
                          className={
                            emailSendFields.email.isValid ? "" : "is-invalid"
                          }
                          autoComplete="off"
                        />
                        {!emailSendFields.email.isValid && (
                          <div className="invalid-feedback">
                            Please enter a valid email.
                          </div>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">First Name</label>
                        <Input
                          placeholder="First Name"
                          type="text"
                          name="firstName"
                          maxLength={50}
                          value={emailSendFields.firstName.value}
                          onChange={(e) =>
                            handleChangeSendEmail(e.target.name, e.target.value)
                          }
                          onBlur={(e) =>
                            onBlurSendEmail(e.target.name, e.target.value)
                          }
                          className={
                            emailSendFields.firstName.isValid
                              ? ""
                              : "is-invalid"
                          }
                          autoComplete="off"
                        />
                        {!emailSendFields.firstName.isValid && (
                          <div className="invalid-feedback">
                            First name should contain only alphabets.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">Last Name</label>
                        <Input
                          placeholder="Last Name"
                          type="text"
                          name="lastName"
                          value={emailSendFields.lastName.value}
                          onChange={(e) =>
                            handleChangeSendEmail(e.target.name, e.target.value)
                          }
                          onBlur={(e) =>
                            onBlurSendEmail(e.target.name, e.target.value)
                          }
                          className={
                            emailSendFields.lastName.isValid ? "" : "is-invalid"
                          }
                          autoComplete="off"
                          maxLength={50}
                        />
                        {!emailSendFields.lastName.isValid && (
                          <div className="invalid-feedback">
                            Last name should contain only alphabets.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">Field1</label>
                        <Input
                          placeholder="Field1"
                          type="text"
                          name="field1"
                          maxLength={255}
                          value={emailSendFields.field1.value}
                          onChange={(e) =>
                            handleChangeSendEmail(e.target.name, e.target.value)
                          }
                          onBlur={(e) =>
                            onBlurSendEmail(e.target.name, e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">Field2</label>
                        <Input
                          placeholder="Field2"
                          type="text"
                          name="field2"
                          maxLength={255}
                          value={emailSendFields.field2.value}
                          onChange={(e) =>
                            handleChangeSendEmail(e.target.name, e.target.value)
                          }
                          onBlur={(e) =>
                            onBlurSendEmail(e.target.name, e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">Field3</label>
                        <Input
                          placeholder="Field3"
                          type="text"
                          name="field3"
                          maxLength={255}
                          value={emailSendFields.field3.value}
                          onChange={(e) =>
                            handleChangeSendEmail(e.target.name, e.target.value)
                          }
                          onBlur={(e) =>
                            onBlurSendEmail(e.target.name, e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">Field4</label>
                        <Input
                          placeholder="Field4"
                          type="text"
                          name="field4"
                          maxLength={255}
                          value={emailSendFields.field4.value}
                          onChange={(e) =>
                            handleChangeSendEmail(e.target.name, e.target.value)
                          }
                          onBlur={(e) =>
                            onBlurSendEmail(e.target.name, e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <label className="form-control-label">Field5</label>
                        <Input
                          placeholder="Field5"
                          type="text"
                          name="field5"
                          maxLength={255}
                          value={emailSendFields.field5.value}
                          onChange={(e) =>
                            handleChangeSendEmail(e.target.name, e.target.value)
                          }
                          onBlur={(e) =>
                            onBlurSendEmail(e.target.name, e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        color="info"
                        className="mt-1"
                        onClick={() => {
                          //handleNext();
                          setShowLoader(true);
                          handleSendTestEmail();
                        }}
                        size="md"
                        disabled={
                          showLoader ||
                          emailSendFields.email.value.trim().length === 0 ||
                          !emailSendFields.email.isValid ||
                          !emailSendFields.firstName.isValid ||
                          !emailSendFields.lastName.isValid
                        }
                      >
                        {showLoader ? (
                          <i className="fa fa-spinner fa-spin fa-lg text-white" />
                        ) : (
                          "Send"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardBody> */}
                {/* <CardFooter>
                  
                </CardFooter> */}
              </Card>
            )}
            <p className="h4">
              Dynamic Parameters Replaced by temporary values
            </p>
            <ul>
              {showParams?.map((item, index) => (
                <li key={item.value}>
                  <div className="d-flex">
                    <p className="mr-2">
                      {item.value} : {reReplace(item.value)}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
            {/* <ul>
              <li>
                <div className="d-flex">
                  <p className="mb-0 mr-2">{"{first-name}:"}</p>
                  <p>Jonh</p>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <p className="mb-0 mr-2">{"{last-name}:"}</p>
                  <p>Smith</p>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <p className="mb-0 mr-2">{"{attendee-email}:"}</p>{" "}
                  <p>jonhsmith@gmail.com</p>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <p className="mb-0 mr-2">{`{field1}:`}</p>
                  <p>Field1_value_from_csv</p>
                </div>
              </li>
              <li>
                {" "}
                <div className="d-flex">
                  <p className="mb-0 mr-2">{`{field2}`}:</p>{" "}
                  <p>Field2_value_from_csv</p>
                </div>
              </li>
              <li>
                {" "}
                <div className="d-flex">
                  <p className="mb-0 mr-2">{`{field2}`}:</p>{" "}
                  <p>Field3_value_from_csv</p>
                </div>
              </li>
              <li>
                {" "}
                <div className="d-flex">
                  <p className="mb-0 mr-2">{`{field4}`}:</p>{" "}
                  <p>Field4_value_from_csv</p>
                </div>
              </li>
              <li>
                {" "}
                <div className="d-flex">
                  <p className="mb-0 mr-2">{`{field5}`}:</p>{" "}
                  <p>Field5_value_from_csv</p>
                </div>
              </li>
            </ul> */}
            {/* </CardBody>
              </Card> */}
          </Col>
          {/* </Row> */}
        </Row>
      </ModalBody>
      {/* </div> */}
      <ModalFooter>
        <Button
          color="info"
          outline
          className="mt-1"
          onClick={() => {
            props.toggleModal();
          }}
        >
          Edit
        </Button>
        <Button
          color="info"
          className="mt-1"
          onClick={() => {
            props.handleNext();
          }}
        >
          Proceed
        </Button>
      </ModalFooter>
    </Modal>
  );
}
export default PreviewModal;

const styles = {
  descriptionContainer: {
    border: "1px solid #ddd",
    borderRadius: "0.3rem",
    wordWrap: "break-word",
  },
  modal: { minWidth: "90%" },
  colScroll: { maxHeight: "calc(100vh - 300px)", overflowY: "auto" },
};
