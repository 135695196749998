import { Modal, ModalBody, ModalHeader } from "reactstrap";

function InstructionModal(props) {
  return (
    <Modal
      isOpen={props.show}
      toggle={() => props.handleToggle()}
      size={props.size ? props.size : "xl"}
      centered>
      {props.videoName && (
        <ModalHeader toggle={() => props.handleToggle()} className="pb-0">
          {props.videoName}
        </ModalHeader>
      )}
      <ModalBody className="p-2">
        <div className="video-container">
          <iframe
            // width="1200px"
            // height="700"
            src={props.youtubeEmbededURL}
            title="YouTube video player"
            // frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen="allowfullscreen"></iframe>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default InstructionModal;
// const styles = {
//   videoContainer: {
//     width: "70%",
//     maxWidth: "1200px",
//     height: "80vh",
//     maxHeight: "800px",
//   },
// };
