import React, { useEffect, useRef, useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Table,
  Modal,
  ModalBody,
  Input,
  Label,
  InputGroup,
  InputGroupText,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// core components
import { attendeeListDropdown, columnsList } from "../Common/constants";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { CREATE_CAMPAIGN_STEP2 } from "API/apiConstants";
import { getAttendeeList } from "action/campaign/createCampaign";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "components/Common/Pagination";
import { default as Select, components } from "react-select";
import {
  inputBorderColor,
  inputTextColor,
  cyanBlue,
  selectedColor,
  CAMPAIGN_STATUS_IN_PROGRESS,
} from "components/Common/constants";
import { bulkUpdateAttendee } from "action/campaign/createCampaign";
import { formatDateWithTime } from "components/CommonFunctions";
import {
  clearAttendeeListFrmStore,
  getCampaignDetails,
} from "action/campaign/createCampaign";
import ErrorDisplay from "./ErrorsDisplay";
import AddOrEditAttendeeModal from "../Common/AddOrEditAttendeeModal";
import { BulkSendCalendarInviteModal } from "../CampaignDetails/BulkSendModal";
import InviteSendingProgress from "../CampaignDetails/InviteSendingProgress";
import { setAppliedFiltersOnAttendees } from "action/campaign/createCampaign";
import CountsCard from "../Common/CountsCard";
import ColumnFilterDropdown from "../Common/ColumnFilterDropdown";
import Warning from "components/Common/Warning";
import Swal from "sweetalert2";
import { showInProgressAlert } from "components/CommonFunctions";

function CreateCampaignStep2() {
  const tableRef = useRef(null);
  const [cardFilter, setCardFilter] = useState("");
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  //states for import file
  const [selectedCsv, setSelectedCsv] = useState(null);
  const [updateExisting, setUpdateExisting] = useState("NO");
  const [showLoaderOnClick, setShowLoaderOnClick] = useState(false);
  //states for the modal
  const [modalState, setModalState] = useState({
    show: false,
    mode: "add",
    emailSendFields: {
      firstName: { value: "", isValid: true },
      email: { value: "", isValid: true },
      lastName: { value: "", isValid: true },
      field1: { value: "", isValid: true },
      field2: { value: "", isValid: true },
      field3: { value: "", isValid: true },
      field4: { value: "", isValid: true },
      field5: { value: "", isValid: true },
    },
  });
  //states for attendee list
  const [campaignCode, setCampaignCode] = useState(null);
  const [showListAlternative, setShowListAlternative] = useState({
    loader: true,
    noRecordsFound: false,
  });
  const [pageNum, setPageNum] = useState(1);
  const [selectedMoreCols, setSelectedMoreCols] = useState([]);
  const [filter, setFilter] = useState({
    search: "",
    status: null,
  });
  const [filterParams, setFilterParams] = useState("");
  const [attendeeListRecords, setAttendeeListRecords] = useState([]);
  const [errors, setErrors] = useState({ data: [], message: "" });
  const [showLoaderOnSearch, setShowLoaderOnSearch] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "DESC",
    sortParam: "",
  });
  const [inviteCount, setInviteCount] = useState({
    sentCount: 0,
    pendingCount: 0,
    failedCount: 0,
    attendeeCount: 0,
  });

  const notificationAlertRef = React.useRef(null);

  const campaignDetails = useSelector(
    (state) => state.createCampaign.campaignDetails
  );
  const attendeeList = useSelector(
    (state) => state.createCampaign.attendeeList
  );

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //For making API call after pressing the card
  useEffect(() => {
    if (cardFilter !== "") {
      cardSearch(cardFilter); // API call by passing the card value directly
      const options = attendeeListDropdown;
      options.find((item) => {
        if (item.value === cardFilter && cardFilter !== "TOTAL") {
          setFilter((prevState) => {
            return {
              ...prevState,
              search: "",
              status: {
                label: item.label,
                value: item.value,
              },
            };
          });
        } else if (cardFilter === "TOTAL") {
          setFilter((prevState) => {
            return {
              ...prevState,
              search: "",
              status: null,
            };
          });
        }
      });
    }
  }, [cardFilter]);

  useEffect(() => {
    if (location.state?.campaignCode) {
      setCampaignCode(location.state?.campaignCode);
    } else {
      // notify("error", {
      //   message: "Please complete create campaign step 1 first.",
      //   subMessage: "",
      // });
      //when any one directly try to navigate to the step 2
      navigate("/a/campaigns/step-1");
    }
    return () => {
      clearAttendeeListFrmStore(dispatch);
    };
  }, []);

  useEffect(() => {
    if (campaignCode) {
      getCampaignDetails(dispatch, campaignCode);
    }
  }, [campaignCode]);

  //call to get attendee list
  useEffect(() => {
    //get attendee list based on selected
    if (campaignCode && pageNum) {
      getAttendeeList(
        dispatch,
        campaignCode,
        pageNum,
        filterParams,
        sortConfig.sortParam
      );

      //to make show loader only when an api call has happend
      if (filterParams !== "") {
        setShowLoaderOnSearch(true);
      }

      //store the filter applied on list in redux
      const filters = {
        pageNum: pageNum,
        filterParams: filterParams,
        sortParam: sortConfig.sortParam,
      };
      setAppliedFiltersOnAttendees(dispatch, filters);
    }
  }, [pageNum, campaignCode, filterParams, sortConfig.sortParam]);

  //setting the state of attendee list or in other case showing loader or not found msg
  useEffect(() => {
    if (
      attendeeList.CampaignAttendee &&
      attendeeList.CampaignAttendee.length > 0
    ) {
      const attendeeListWithCheckbox = attendeeList.CampaignAttendee.map(
        (item) => {
          return {
            ...item,
            checked: false,
          };
        }
      );
      setAttendeeListRecords(attendeeListWithCheckbox);
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
      });
      setShowLoaderOnSearch(false);
    } else if (
      attendeeList.CampaignAttendee &&
      attendeeList.CampaignAttendee.length === 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: true,
      });
      setAttendeeListRecords([]);
      setShowLoaderOnSearch(false);
    }
  }, [attendeeList]);

  //on click of download file
  const handleDownload = () => {
    // Path to your CSV file
    const filePath = require("../../../assets/img/Misc/import attendee sample.csv");

    // Create a link element
    const link = document.createElement("a");
    link.href = filePath;

    // Set the download attribute to suggest a filename
    link.download = "sample.csv";

    // Append the link to the body (necessary for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up the link by removing it from the DOM
    document.body.removeChild(link);
  };

  //notification component
  const notify = (type, msgObj) => {
    if (isNotificationOpen && !msgObj.showForceFully) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {msgObj.message}
          </span>
          <span data-notify="message">{msgObj.subMessage}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };

  //on click of Import button
  const handleImport = () => {
    //condition checked that if calendar is in progress so to restrict further action
    if (
      campaignDetails &&
      campaignDetails.status === CAMPAIGN_STATUS_IN_PROGRESS
    ) {
      return showInProgressAlert();
    }

    handleCreateCampaignStep2();
    setShowLoaderOnClick(true);
  };

  //Import file upload
  const handleCreateCampaignStep2 = async () => {
    let headers = new Headers();
    const p = JSON.parse(localStorage.getItem("p"));
    headers.append("token", p.token);
    //  headers.append("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("file", selectedCsv);
    formData.append("updateRecords", updateExisting);
    await fetch(`${CREATE_CAMPAIGN_STEP2}${campaignCode}`, {
      method: "POST",
      headers: headers,
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 200) {
          notify("success", {
            message: res.message || "Attendee Imported Sucessfully!",
            subMessage: "",
          });
          setSelectedCsv(null);
          if (res?.data?.errorLink) {
            setErrors({ data: res.data, message: res.message });
          }
          if (pageNum === 1) {
            getAttendeeList(
              dispatch,
              campaignCode,
              pageNum,
              filterParams,
              sortConfig.sortParam
            );
            getCampaignDetails(dispatch, campaignCode);
          } else {
            setPageNum(1);
          }
        } else if (res.code === 400) {
          setErrors({ data: res.data, message: res.message });
        } else {
          notify("error", {
            message: res.message ? res.message : "Something went wrong",
            subMessage: "Please check the CSV file.",
          });
        }
        setShowLoaderOnClick(false);
      });
  };

  //on select of file
  const handleSelectCsv = (e) => {
    if (e.target.files && e.target.files[0]) {
      // Convert file size to MB
      const fileSizeInMB = e.target.files[0].size / (1024 * 1024);
      // Check if file size exceeds 50MB
      if (fileSizeInMB > 50) {
        notify("error", {
          message: "File with a maximum size of 50MB is allowed",
          subMessage: "",
        });
        // alert(
        //   "File with a maximum size of 50MB is allowed"
        // );
        setSelectedCsv(null);
      } else {
        setSelectedCsv(e.target.files[0]);
        //remove error
        setErrors({ data: [], message: "" });
      }
    }
    // Reset the input value to allow selecting the same file again
    e.target.value = "";
  };

  //for customizing the appearance of the React Select to match other input fields
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.selectProps.className?.includes("is-invalid")
        ? "red"
        : inputBorderColor,
      height: "2.8rem",
      fontSize: "0.85rem",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: inputTextColor,
    }),
    menuList: (provided) => ({
      ...provided,
      fontSize: "0.8rem",
    }),
  };

  //on change of filter in attendee list
  const handleFilterChange = (name, value) => {
    setFilter((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  //to calulate the serial number based on the page Num
  const calculateSerialNum = (index) => {
    // Calculate the serial number based on the current page number
    const serialNum =
      (pageNum - 1) * attendeeList.noOfRecordsPerPage + index + 1;

    return serialNum;
  };

  //on check and uncheck the attendee list rows
  const handleCheckAttendeeList = (index, flagForAll) => {
    if (index === -1) {
      const attendeeListWithCheckbox = attendeeListRecords.map((item) => {
        return {
          ...item,
          checked: !flagForAll,
        };
      });
      setAttendeeListRecords(attendeeListWithCheckbox);
    } else {
      const newAttendeeList = [...attendeeListRecords];
      newAttendeeList[index] = {
        ...newAttendeeList[index],
        checked: !newAttendeeList[index].checked,
      };
      setAttendeeListRecords(newAttendeeList);
    }
  };

  //for success alert
  const successAlert = (action) => {
    Swal.fire({
      title: `${action}!`,
      icon: "success",
      timer: 3000,
      text: `Record ${action} successfully`,
      showCancelButton: false,
      showConfirmButton: false,
    });
  };

  //for error alert
  const errorAlert = (action, msg) => {
    Swal.fire({
      title: `Something went wrong!`,
      icon: "error",
      timer: 3000,
      text: `Not ${action}.${msg ? msg : null}`,
      showCancelButton: false,
      showConfirmButton: false,
    });
  };

  //delete api for single as well as bulk delete
  const deleteAttendee = async (ID) => {
    const deleteAttendeeID = ID
      ? [ID]
      : attendeeListRecords
          .filter((item) => item.checked)
          .map((item) => {
            return item.ID;
          });
    const res = await bulkUpdateAttendee(campaignCode, {
      status: "DELETED",
      campaignAttendeeID: deleteAttendeeID,
    });
    if (res.code === 200) {
      successAlert("Deleted");
      // called attendee list with applied params of search or sort and page if any
      getAttendeeList(
        dispatch,
        campaignCode,
        pageNum,
        filterParams,
        sortConfig.sortParam
      );
      getCampaignDetails(dispatch, campaignCode);
    } else {
      if (Array.isArray(res.message)) {
        errorAlert("Deleted", res.message[0].Msg);
      } else {
        errorAlert("Deleted", res.message);
      }
    }
  };

  //api call to bulk update status
  const bulkUpdateStatus = async (status) => {
    const attendeeID = attendeeListRecords
      .filter((item) => item.checked)
      .map((item) => {
        return item.ID;
      });
    const res = await bulkUpdateAttendee(campaignCode, {
      status: status,
      campaignAttendeeID: attendeeID,
    });
    if (res.code === 200) {
      successAlert("Updated");
      getAttendeeList(
        dispatch,
        campaignCode,
        pageNum,
        filterParams,
        sortConfig.sortParam
      );
      getCampaignDetails(dispatch, campaignCode);
    } else {
      if (Array.isArray(res.message)) {
        errorAlert("Updated", res.message[0].Msg);
      } else {
        errorAlert("Updated", res.message);
      }
    }
  };

  //to show loader while the api call is in progress
  const showModalLoader = (action) => {
    Swal.fire({
      title: `${action}...`,
      html: '<i class="fa fa-spinner fa-spin fa-2xl m-5 text-info"></i>',
      showConfirmButton: false,
      allowOutsideClick: false,
    });
  };

  //delete warning
  const deleteWarning = (ID) => {
    //condition checked that if calendar is in progress so to restrict further action
    if (
      campaignDetails &&
      campaignDetails.status === CAMPAIGN_STATUS_IN_PROGRESS
    ) {
      return showInProgressAlert();
    }

    Swal.fire({
      title: "Are you sure?",
      text: ID
        ? "You want to delete this record?"
        : `You want to delete selected ${
            attendeeListRecords.filter((item) => item.checked).length
          } records?`,
      showCancelButton: true,
      showConfirmButton: true,
      customClass: { confirmButton: "bg-danger", cancelButton: "bg-light" },
      confirmButtonText: "Delete",
      icon: "question",
      reverseButtons: true,
      confirmButtonText: "Delete",

      didOpen: () => {
        // Remove focus from buttons
        const swal = Swal.getPopup();
        swal.querySelector(".swal2-confirm")?.blur();
        swal.querySelector(".swal2-cancel")?.blur();
      },
    }).then((result) => {
      if (result.isConfirmed) {
        showModalLoader("Deleting");
        deleteAttendee(ID);
      }
    });
  };

  //status change warning
  const bulkStatusChange = (status) => {
    //condition checked that if calendar is in progress so to restrict further action
    if (
      campaignDetails &&
      campaignDetails.status === CAMPAIGN_STATUS_IN_PROGRESS
    ) {
      return showInProgressAlert();
    }

    Swal.fire({
      title: "Are you sure?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
      customClass: { confirmButton: "bg-info", cancelButton: "bg-light" },
      text: `You want to change the status of the selected ${
        attendeeListRecords.filter((item) => item.checked).length
      } records to ${status === "DONT_SEND" ? "Don't Send" : "Pending"}?`,
      reverseButtons: true,
      didOpen: () => {
        // Remove focus from buttons
        const swal = Swal.getPopup();
        swal.querySelector(".swal2-confirm")?.blur();
        swal.querySelector(".swal2-cancel")?.blur();
      },
    }).then((result) => {
      if (result.isConfirmed) {
        showModalLoader("updating");
        bulkUpdateStatus(status);
      }
    });
  };

  //toggle the modal state of attendee modal
  const toggleModalState = (mode, item) => {
    let attendeeData = {
      firstName: { value: "", isValid: true },
      email: { value: "", isValid: true },
      lastName: { value: "", isValid: true },
      field1: { value: "", isValid: true },
      field2: { value: "", isValid: true },
      field3: { value: "", isValid: true },
      field4: { value: "", isValid: true },
      field5: { value: "", isValid: true },
    };
    if (item) {
      attendeeData = {
        ID: item.ID,
        firstName: {
          value: item.firstName === " " ? "" : item.firstName.trim(),
          isValid: true,
        },
        email: { value: item.email, isValid: true },
        lastName: {
          value: item.lastName === " " ? "" : item.lastName.trim(),
          isValid: true,
        },
        field1: {
          value: item.field1 === " " ? "" : item.field1.trim(),
          isValid: true,
        },
        field2: {
          value: item.field2 === " " ? "" : item.field2.trim(),
          isValid: true,
        },
        field3: {
          value: item.field3 === " " ? "" : item.field3.trim(),
          isValid: true,
        },
        field4: {
          value: item.field4 === " " ? "" : item.field4.trim(),
          isValid: true,
        },
        field5: {
          value: item.field5 === " " ? "" : item.field5.trim(),
          isValid: true,
        },
      };
    }
    setModalState({
      show: !modalState.show,
      mode: mode,
      emailSendFields: attendeeData,
    });
  };

  const handleSearch = () => {
    let querryParmFilter = "";
    //make filters query params
    if (filter.search.trim().length > 0) {
      querryParmFilter = "&";
      querryParmFilter += `searchString=${filter.search.toString()}`;
    }
    if (filter.status !== null && filter.status.value !== "") {
      querryParmFilter += `&filtersJSON=[{"field": "Status", "condition": "eq", "filterValues": [${JSON.stringify(
        filter.status.value
      )}]}]`;
    }

    setFilterParams(querryParmFilter);
  };

  // To seach by card click
  const cardSearch = (cardFilter) => {
    let querryParmFilter = "";
    if (cardFilter && cardFilter !== "TOTAL") {
      querryParmFilter += `&filtersJSON=[{"field": "Status", "condition": "eq", "filterValues": [${JSON.stringify(
        cardFilter
      )}]}]`;
    } else if (cardFilter === "TOTAL") {
      querryParmFilter += "";
    }
    if (pageNum === 1) {
      setFilterParams(querryParmFilter);
    } else {
      setFilterParams(querryParmFilter);
      setPageNum(1);
    }
  };

  const handleClear = () => {
    setFilterParams("");
    setFilter({
      search: "",
      status: null,
    });
  };

  //on click of send test calendar invite button
  const sendTestCalendarInvite = () => {
    if (
      campaignDetails &&
      campaignDetails.status === CAMPAIGN_STATUS_IN_PROGRESS
    ) {
      return showInProgressAlert();
    }

    if (attendeeListRecords.filter((item) => item.checked).length === 1) {
      toggleModalState(
        "test",
        attendeeListRecords.filter((item) => item.checked)[0]
      );
    } else {
      toggleModalState("test");
    }
  };

  //for handling sor t by col and its direction
  const handleSort = (colname) => {
    let newDirection = sortConfig.direction === "ASC" ? "DESC" : "ASC";
    setSortConfig({
      key: colname,
      direction: newDirection,
      sortParam: `&sortOptionJSON={"SortBy":"${colname}","SortType":"${newDirection}"}`,
    });
  };

  const onCardClick = (filterType) => {
    setCardFilter(filterType);
    tableRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  //on click of Add Attendee btn
  const handleAddAttendeeClick = () => {
    //condition checked that if calendar is in progress so to restrict further action
    if (
      campaignDetails &&
      campaignDetails.status === CAMPAIGN_STATUS_IN_PROGRESS
    ) {
      return showInProgressAlert();
    }
    toggleModalState("add");
  };

  //handle on click of edit single record
  const handleEditSingleAttendee = (item) => {
    //condition checked that if calendar is in progress so to restrict further action
    if (
      campaignDetails &&
      campaignDetails.status === CAMPAIGN_STATUS_IN_PROGRESS
    ) {
      return showInProgressAlert();
    }
    toggleModalState("edit", item);
  };

  return (
    <>
      <Warning />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {/* New common Modal added for edit or add attendee */}
      <AddOrEditAttendeeModal
        openModal={modalState.show}
        toggleModalState={toggleModalState}
        modalState={modalState}
        setModalState={setModalState}
        campaignCode={campaignCode}
        notify={notify}
        attendeeListRecords={attendeeListRecords}
        usedDynamicParams={campaignDetails?.placeHolders}
        pageNum={pageNum}
        setPageNum={setPageNum}
        filterParams={filterParams}
        sortParam={sortConfig.sortParam}
      />
      <Container className="mt--6" fluid>
        <InviteSendingProgress
          campaignCode={campaignCode}
          campaignDetails={campaignDetails}
          unsentCount={campaignDetails?.unsendCount}
          dispatch={dispatch}
          notify={notify}
          inviteCount={inviteCount}
          setInviteCount={setInviteCount}
        />
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="12" md="6">
                <h3 className="mb-2 d-flex">
                  {" "}
                  <i
                    className="fa fa-arrow-left mr-3 mt-1"
                    style={styles.cursor}
                    onClick={() => {
                      navigate("/a/campaigns/step-1", {
                        state: { campaignCode: campaignCode },
                      });
                    }}
                  />{" "}
                  <div>
                    {campaignDetails?.campaignName} -{" "}
                    <span style={styles.stepStyle}>Step 2/2</span>
                  </div>
                </h3>
              </Col>
              <Col xs="12" md="6" className="text-md-right mt-2 mt-md-0">
                <div className="d-flex justify-content-end gap-4 flex-column flex-md-row ">
                  {attendeeListRecords.length > 0 && (
                    <Button
                      className="mr-0 mr-md-2 mb-md-0 mb-2"
                      color="info"
                      onClick={() => {
                        navigate("/a/campaigns/details", {
                          state: { campaignCode: campaignCode },
                        });
                      }}
                    >
                      Calendar Details
                    </Button>
                  )}
                  {(campaignDetails?.status === "PENDING" ||
                    campaignDetails?.status === "MODIFIED") &&
                    campaignDetails?.unsendCount > 0 && (
                      <BulkSendCalendarInviteModal
                        availableCredits={
                          campaignDetails?.tenantAvailableCredits
                        }
                        creditsToBeUsed={campaignDetails?.unsendCount}
                        campaignCode={campaignCode}
                        numberOfRecipients={campaignDetails?.unsendCount}
                        notify={notify}
                        dispatch={dispatch}
                        navigate={navigate}
                      />
                    )}
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <span className="font-weight-bold">Import Attendees</span>
                <FormGroup className="mt-4">
                  <Label className="form-control-label">
                    Select CSV file to Upload
                  </Label>

                  <div className="custom-file">
                    <input
                      type="file"
                      accept=".csv, text/csv"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleSelectCsv(e);
                      }}
                      id="csvFile"
                    />
                    <label htmlFor="csvFile" className="custom-file-label">
                      {selectedCsv ? selectedCsv.name : "Choose CSV file"}
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      columnGap: "0.5rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    <i
                      className={
                        updateExisting === "YES"
                          ? "fa-solid fa-square-check fa-md text-primary"
                          : "fa-regular fa-square fa-md"
                      }
                      onClick={() =>
                        setUpdateExisting(
                          updateExisting === "YES" ? "NO" : "YES"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    ></i>
                    <Label
                      check
                      className="form-control-label"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setUpdateExisting(
                          updateExisting === "YES" ? "NO" : "YES"
                        )
                      }
                    >
                      Update Existing Attendee Details
                    </Label>
                  </div>
                  <div className="mt-2">
                    <p style={{ fontSize: "0.9rem" }}>
                      Note: You can upload 100,000 records in one calendar and
                      add verified email addresses please
                    </p>
                    <p
                      className="text-primary font-weight-600 "
                      style={styles.csvExampleInnerText}
                      onClick={() => handleDownload()}
                    >
                      <i className="fa fa-download mr-2"></i>
                      Download a sample CSV file
                    </p>
                  </div>
                </FormGroup>
              </Col>
              <Col>
                <span className="font-weight-bold">
                  CSV file format Requirements
                </span>
                <ul className="my-2">
                  <li>
                    A valid email is required and must be less than 100
                    characters.
                  </li>
                  <li>FirstName(optional) must be less than 50 characters.</li>
                  <li>LastName(optional) must be less than 50 characters.</li>

                  <li>
                    Ensure there are no extra rows or columns in your CSV file.
                  </li>
                </ul>
                {campaignDetails.emailConfiguration?.mailingService ===
                  "Gmail(Google)" && (
                  <>
                    <span className="font-weight-bold">
                      Note For Google SMTP Integration
                    </span>
                    <ul className="my-2">
                      <li>
                        You can send up to 500 calendar invites per day for a
                        regular Gmail account
                      </li>
                      <li>
                        For a Google Workspace account, the limit is 2,000
                        calendar invites per day.
                      </li>
                    </ul>
                  </>
                )}
              </Col>
            </Row>

            <Row className="d-flex justify-content-center">
              <Button
                block={window.innerWidth < 768}
                color="info"
                onClick={() => {
                  handleImport();
                }}
                disabled={selectedCsv === null || showLoaderOnClick}
              >
                {showLoaderOnClick ? (
                  <i className="fa fa-spinner fa-spin fa-lg text-white" />
                ) : (
                  "Import"
                )}
              </Button>
            </Row>
            {errors.message && (
              <ErrorDisplay errors={errors.data} title={errors.message} />
            )}
          </CardBody>
        </Card>
        <CountsCard
          tableRef={tableRef}
          onCardClick={onCardClick}
          showStatus={true}
          campaignDetails={campaignDetails}
          inviteCount={inviteCount}
        />
        <div ref={tableRef}>
          <Card>
            <CardHeader className="border-0">
              <Row className="pl-3 d-flex align-items-center justify-content-between">
                <h3 className="mt-2">Attendee List</h3>

                <>
                  {/* Align Select More Columns and other buttons on the right with responsive class */}
                  {windowWidth > 768 ? (
                    <Col
                      className=" px-0 d-flex justify-content-end gap-2 float-right"
                      xs="12"
                      md="8"
                    >
                      {attendeeListRecords.filter((item) => item.checked)
                        .length < 2 && (
                        <Button
                          className="btn-icon"
                          color="success"
                          outline
                          type="button"
                          onClick={() => sendTestCalendarInvite()}
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-email-83" />
                          </span>
                          <span className="btn-inner--text">
                            Send Test Calendar Invite
                          </span>
                        </Button>
                      )}
                      <Button
                        className="btn-icon mr-0"
                        color="info"
                        outline
                        type="button"
                        onClick={() => handleAddAttendeeClick()}
                      >
                        <span className="btn-inner--icon">
                          <i className="fa-solid fa-plus" />
                        </span>
                        <span className="btn-inner--text">Add Attendee</span>
                      </Button>
                    </Col>
                  ) : (
                    <UncontrolledDropdown className="mt--1">
                      <DropdownToggle className="nav-link" color="" tag="a">
                        <Media className="align-items-center">
                          <Media className="d-flex d-lg-block align-items-center">
                            <span
                              className="btn-inner--icon"
                              style={{ cursor: "pointer" }}
                            >
                              <i className="fa fa-bars" />
                            </span>
                          </Media>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={() => sendTestCalendarInvite()}>
                          <i className="ni ni-email-83" />
                          <span>Send Test Calendar Invite</span>
                        </DropdownItem>

                        <DropdownItem onClick={() => handleAddAttendeeClick()}>
                          <i className="fa-solid fa-plus" />
                          <span>Add Attendee</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </>
              </Row>
              <hr className="my-3" />

              <Row className="p-0 align-items-center">
                {/* Filter options on the right side */}
                <Col
                  xs={12}
                  md={7}
                  className="d-flex flex-wrap justify-content-start align-items-center p-0"
                >
                  {/* Search Input */}
                  <Col xs={12} sm={6} md={5} className="mb-2 mb-md-0 pr-md-0">
                    <InputGroup>
                      <InputGroupText
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      >
                        <i className="fa fa-search" />
                      </InputGroupText>
                      <Input
                        placeholder="Search"
                        type="text"
                        name="search"
                        autoComplete="off"
                        value={filter.search}
                        onChange={(e) =>
                          handleFilterChange(e.target.name, e.target.value)
                        }
                      />
                    </InputGroup>
                  </Col>

                  {/* Select Status */}
                  <Col xs={12} sm={6} md={3} className="mb-2 mb-md-0 pr-md-0">
                    <Select
                      options={attendeeListDropdown}
                      placeholder="Search by status"
                      onChange={(selected, e) =>
                        handleFilterChange("status", selected)
                      }
                      styles={customStyles}
                      value={filter.status}
                      isSearchable={true}
                      isClearable
                    />
                  </Col>

                  {/* Search Button */}
                  <Col xs="auto" className="mb-2 mb-md-0">
                    <Button
                      className="btn-round btn-icon"
                      color="info"
                      outline
                      onClick={() => handleSearch()}
                      size="md"
                      disabled={showLoaderOnSearch}
                    >
                      {showLoaderOnSearch ? (
                        <i className="fa fa-spinner fa-spin fa-lg text-info px-3" />
                      ) : (
                        "Search"
                      )}
                    </Button>
                  </Col>

                  {/* Clear Button */}
                  {filterParams !== "" && (
                    <Col xs="auto" className="mb-2 mb-md-0 p-0">
                      <Button
                        className="btn-round btn-icon"
                        color="light"
                        outline
                        onClick={handleClear}
                        size="md"
                      >
                        Clear
                      </Button>
                    </Col>
                  )}
                </Col>

                {/* Action Buttons on the left side */}

                <Col
                  xs={12}
                  md={5}
                  className="d-flex justify-content-end gap-2"
                >
                  {attendeeListRecords.some((item) => item.checked) && (
                    <>
                      {/* Mark as Unsent */}
                      {attendeeListRecords.some(
                        (item) =>
                          item.checked &&
                          (item.status === "DONT_SEND" ||
                            item.status === "SENT")
                      ) && (
                        <>
                          <Button
                            className="btn-round btn-icon"
                            color="light"
                            outline
                            id="tooltipUnsent"
                            onClick={() => bulkStatusChange("UNSEND")}
                            size="sm"
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fa-solid fa-envelope-open"></i>
                            </span>
                            <span className="btn-inner--text">
                              Mark as Pending
                            </span>
                          </Button>
                          <UncontrolledTooltip delay={0} target="tooltipUnsent">
                            Mark Selected as Pending
                          </UncontrolledTooltip>
                        </>
                      )}

                      {/* Mark as Don't Send */}
                      {attendeeListRecords.some(
                        (item) =>
                          item.checked &&
                          (item.status === "UNSEND" || item.status === "SENT")
                      ) && (
                        <>
                          <Button
                            className="btn-round btn-icon"
                            color="light"
                            id="tooltipDontSend"
                            onClick={() => bulkStatusChange("DONT_SEND")}
                            size="sm"
                            outline
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-close" />
                            </span>
                            <span className="btn-inner--text">
                              Mark as Don't Send
                            </span>
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltipDontSend"
                          >
                            Mark Selected as Don't Send
                          </UncontrolledTooltip>
                        </>
                      )}

                      {/* Delete Button */}
                      <Button
                        className="btn-round btn-icon"
                        color="danger"
                        id="tooltip28070728"
                        onClick={() => deleteWarning()}
                        size="sm"
                        outline
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-trash" />
                        </span>
                        <span className="btn-inner--text">Delete</span>
                      </Button>
                      <UncontrolledTooltip delay={0} target="tooltip28070728">
                        Delete Selected
                      </UncontrolledTooltip>
                    </>
                  )}
                  <ColumnFilterDropdown
                    columnsList={columnsList}
                    selectedMoreCols={selectedMoreCols}
                    setSelectedMoreCols={setSelectedMoreCols}
                    attendeeList={attendeeList}
                  />
                </Col>
              </Row>

              {/* )} */}
            </CardHeader>

            {showListAlternative.loader ? (
              <div style={styles.loaderContainer}>
                <i className="fa fa-spinner fa-spin fa-2xl text-info" />
              </div>
            ) : showListAlternative.noRecordsFound ? (
              <div style={styles.loaderContainer}>No Records Found!</div>
            ) : (
              <>
                <Table
                  className="align-items-center table-flush"
                  hover
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th>
                        <i
                          className={
                            attendeeListRecords.every((item) => item.checked)
                              ? "fa-solid fa-square-check fa-xl text-primary"
                              : "fa-regular fa-square fa-xl"
                          }
                          onClick={() => {
                            handleCheckAttendeeList(
                              -1,
                              attendeeListRecords.every((item) => item.checked)
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        ></i>
                      </th>
                      <th>Sr. no</th>
                      <th
                        style={styles.cursor}
                        onClick={() => handleSort("Email")}
                      >
                        <span className="d-flex align-items-center justify-content-between">
                          Email ID
                          <span className="d-flex align-items-center">
                            <span
                              style={{
                                color:
                                  sortConfig.key === "Email" &&
                                  sortConfig.direction === "ASC"
                                    ? "gray"
                                    : "lightgray",
                                ...styles.sortIcon,
                              }}
                            >
                              ↑
                            </span>
                            <span
                              style={{
                                color:
                                  sortConfig.key === "Email" &&
                                  sortConfig.direction === "DESC"
                                    ? "gray"
                                    : "lightgray",
                                ...styles.sortIcon,
                              }}
                            >
                              ↓
                            </span>
                          </span>
                        </span>
                      </th>

                      <th
                        style={styles.cursor}
                        onClick={() => handleSort("FirstName")}
                      >
                        <span className="d-flex align-items-center justify-content-between">
                          First Name
                          <span className="d-flex align-items-center">
                            <span
                              style={{
                                color:
                                  sortConfig.key === "FirstName" &&
                                  sortConfig.direction === "ASC"
                                    ? "gray"
                                    : "lightgray",
                                ...styles.sortIcon,
                              }}
                            >
                              ↑
                            </span>
                            <span
                              style={{
                                color:
                                  sortConfig.key === "FirstName" &&
                                  sortConfig.direction === "DESC"
                                    ? "gray"
                                    : "lightgray",
                                ...styles.sortIcon,
                              }}
                            >
                              ↓
                            </span>
                          </span>
                        </span>
                      </th>
                      <th
                        style={styles.cursor}
                        onClick={() => handleSort("LastName")}
                      >
                        <span className="d-flex align-items-center justify-content-between">
                          Last Name
                          <span className="d-flex align-items-center">
                            <span
                              style={{
                                color:
                                  sortConfig.key === "LastName" &&
                                  sortConfig.direction === "ASC"
                                    ? "gray"
                                    : "lightgray",
                                ...styles.sortIcon,
                              }}
                            >
                              ↑
                            </span>
                            <span
                              style={{
                                color:
                                  sortConfig.key === "LastName" &&
                                  sortConfig.direction === "DESC"
                                    ? "gray"
                                    : "lightgray",
                                ...styles.sortIcon,
                              }}
                            >
                              ↓
                            </span>
                          </span>
                        </span>
                      </th>
                      <th
                        style={styles.cursor}
                        onClick={() => handleSort("SentOn")}
                      >
                        <span className="d-flex align-items-center justify-content-between">
                          Sent On
                          <span className="d-flex align-items-center">
                            <span
                              style={{
                                color:
                                  sortConfig.key === "SentOn" &&
                                  sortConfig.direction === "ASC"
                                    ? "gray"
                                    : "lightgray",
                                ...styles.sortIcon,
                              }}
                            >
                              ↑
                            </span>
                            <span
                              style={{
                                color:
                                  sortConfig.key === "SentOn" &&
                                  sortConfig.direction === "DESC"
                                    ? "gray"
                                    : "lightgray",
                                ...styles.sortIcon,
                              }}
                            >
                              ↓
                            </span>
                          </span>
                        </span>
                      </th>
                      <th>Status</th>

                      {selectedMoreCols.length > 0 &&
                        selectedMoreCols.map((colItem) => {
                          return <th key={colItem.label}>{colItem.label}</th>;
                        })}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendeeListRecords.map((item, index) => {
                      return (
                        <tr
                          style={{
                            backgroundColor: item.checked
                              ? selectedColor
                              : "white",
                          }}
                          key={item.email}
                        >
                          <th>
                            <i
                              className={
                                item.checked
                                  ? "fa-solid fa-square-check fa-lg text-primary"
                                  : "fa-regular fa-square fa-lg"
                              }
                              onClick={() => handleCheckAttendeeList(index)}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </th>
                          <td className="text-muted">
                            {calculateSerialNum(index)}
                          </td>
                          <td className="text-muted">
                            <span>{item.email}</span>
                          </td>
                          <td className="text-muted">
                            <span>{item.firstName}</span>
                          </td>
                          <td className="text-muted">
                            <span>{item.lastName}</span>
                          </td>
                          <td className="text-muted">
                            {item.sentOn !== "" ? (
                              <span>{formatDateWithTime(item.sentOn)}</span>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            {item.status === "UNSEND" ? (
                              <span className="text-muted">Pending</span>
                            ) : item.status === "SENT" ? (
                              <span className="text-success font-weight-bold">
                                Sent
                              </span>
                            ) : item.status === "DONT_SEND" ? (
                              <span className="text-primary">Don't Send</span>
                            ) : item.status === "UNSUBSCRIBED" ? (
                              <span className="text-orange">Unsubscribed</span>
                            ) : item.status === "FAILED" ? (
                              <span className="text-red">Failed</span>
                            ) : (
                              <span className="text-muted">{item.status}</span>
                            )}
                          </td>
                          {selectedMoreCols.length > 0 &&
                            selectedMoreCols.map((colItem) => {
                              return (
                                <td className="text-muted" key={colItem.value}>
                                  {item[colItem.value]}
                                </td>
                              );
                            })}
                          <td>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "1rem",
                              }}
                            >
                              <a
                                className="table-action"
                                id={`tooltipEditRecord${item.ID}`}
                                onClick={(e) => {
                                  handleEditSingleAttendee(item);
                                }}
                              >
                                <i className="fas fa-edit" />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target={`tooltipEditRecord${item.ID}`}
                              >
                                Edit Record
                              </UncontrolledTooltip>
                              <div className="text-gray">|</div>
                              <a
                                className="table-action table-action-delete"
                                id={`tooltipDeleteRecord${item.ID}`}
                                onClick={(e) => deleteWarning(item.ID)}
                              >
                                <i className="fas fa-trash" />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target={`tooltipDeleteRecord${item.ID}`}
                              >
                                Delete Record
                              </UncontrolledTooltip>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div>
                  <CustomPagination
                    totalPages={Math.ceil(
                      attendeeList.totalRecords /
                        attendeeList.noOfRecordsPerPage
                    )}
                    setPageNum={setPageNum}
                    activePage={pageNum}
                  />
                </div>
              </>
            )}
          </Card>
        </div>
      </Container>
    </>
  );
}

export default CreateCampaignStep2;

const styles = {
  cursor: { cursor: "pointer" },
  csvExampleInnerText: { cursor: "pointer", fontSize: 14 },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
  actionConatiner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    columnGap: "1rem",
  },
  deleteLoader: {
    backgroundColor: "white",
    display: "flex",
    padding: "2rem",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "2rem",
  },
  rightBtnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  sortIcon: {
    fontSize: "1.5em",
    transform: "scale(1.2)",
  },
  stepStyle: { fontWeight: "400", color: "gray" },
};
