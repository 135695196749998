import moment from "moment";
import Swal from "sweetalert2";

export const replaceQuillClassesWithInlineStyles = (htmlString) => {
  // Create a temporary DOM element to parse the HTML string
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;

  // Define a mapping of class names to corresponding inline styles
  const styleMapping = {
    "ql-align-center": "text-align: center;",
    "ql-align-right": "text-align: right;",
    "ql-align-left": "text-align: left;",
    "ql-align-justify": "text-align: justify;",

    "ql-size-huge": "font-size: xx-large;",
    "ql-size-large": "font-size: x-large;",
    "ql-size-small": "font-size: small;",

    "ql-font-monospace": "font-family: monospace;",
    "ql-font-serif": "font-family: serif;",
    "ql-font-sans": "font-family: sans-serif;",
  };

  // Loop through all elements with a class
  tempDiv.querySelectorAll("[class]").forEach((element) => {
    let inlineStyle = element.getAttribute("style") || "";

    // Apply styles for each class
    element.classList.forEach((cls) => {
      if (styleMapping[cls]) {
        inlineStyle += styleMapping[cls];
      }
    });

    // Set the final inline style
    element.setAttribute("style", inlineStyle.trim());

    // Remove the class attribute
    element.removeAttribute("class");
  });

  const withDivTags = tempDiv.innerHTML
    .replace(/<p>/g, "<div>")
    .replace(/<\/p>/g, "</div>")
    .replace(/<p/g, "<div");

  // Return the modified HTML with inline styles
  return withDivTags;
};

export const revertInlineStylesToQuillClasses = (htmlString) => {
  // Create a temporary DOM element to parse the HTML string
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;

  // Define a reverse mapping from inline styles to class names
  const styleMapping = {
    "text-align: center;": "ql-align-center",
    "text-align: right;": "ql-align-right",
    "text-align: left;": "ql-align-left",
    "text-align: justify;": "ql-align-justify",

    "font-size: xx-large;": "ql-size-huge",
    "font-size: x-large;": "ql-size-large",
    "font-size: small;": "ql-size-small",

    "font-family: monospace;": "ql-font-monospace",
    "font-family: serif;": "ql-font-serif",
    "font-family: sans-serif;": "ql-font-sans-serif",
  };

  // Convert inline styles back to class attributes
  tempDiv.querySelectorAll("[style]").forEach((element) => {
    let inlineStyle = element.getAttribute("style") || "";
    let classes = [];

    // Match each style and convert it back to the corresponding class
    Object.entries(styleMapping).forEach(([style, className]) => {
      if (inlineStyle.includes(style)) {
        classes.push(className);
        inlineStyle = inlineStyle.replace(style, ""); // Remove matched style
      }
    });

    // Apply class attribute if there are any matched classes
    if (classes.length > 0) {
      element.setAttribute("class", classes.join(" "));
    }

    // Remove empty style attributes
    if (inlineStyle.trim() === "") {
      element.removeAttribute("style");
    } else {
      element.setAttribute("style", inlineStyle.trim());
    }
  });

  // Replace <div> back with <p>
  const withPTags = tempDiv.innerHTML
    .replace(/<div>/g, "<p>")
    .replace(/<\/div>/g, "</p>")
    .replace(/<div/g, "<p");

  return withPTags;
};

export const emailRegex =
  /^[a-zA-Z0-9]+([._%+-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
export function formatDateWithTime(inputDateTime) {
  // Parse the input date string into a Date object
  const date = new Date(inputDateTime);

  // Get day, month, year, hour, minute, and second components
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  // const seconds = date.getSeconds();

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Define an array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Format the date in the desired format: dd MonthName yyyy hh:mm:ss AM/PM
  let formattedDate = `${day} ${monthNames[monthIndex]},`;

  // Add year to formatted date if it's not the current year
  if (year !== currentYear) {
    formattedDate += ` ${year}`;
  }

  formattedDate += ` ${hours % 12 || 12}:${minutes < 10 ? "0" : ""}${minutes} ${
    hours < 12 ? "AM" : "PM"
  }`;

  return formattedDate;
}

// Function to format a Date object to a string in 'YYYY-MM-DDTHH:MM' format
export const formatDateTime = (date) => {
  const dateString = new Date(date);

  // Extract the full year (e.g., 2024)
  const year = dateString.getFullYear();

  // Extract the month (0-11), convert to a 2-digit string (e.g., '08' for August)
  // Adding 1 because getMonth() is zero-based
  const month = String(dateString.getMonth() + 1).padStart(2, "0");

  // Extract the day of the month, convert to a 2-digit string (e.g., '20' for the 20th)
  const day = String(dateString.getDate()).padStart(2, "0");

  // Extract the hours (0-23), convert to a 2-digit string (e.g., '14' for 2 PM)
  const hours = String(dateString.getHours()).padStart(2, "0");

  // Extract the minutes (0-59), convert to a 2-digit string (e.g., '05' for 5 minutes past the hour)
  const minutes = String(dateString.getMinutes()).padStart(2, "0");

  // Return the formatted date and time string in 'YYYY-MM-DDTHH:MM' format
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

// Function to calculate the start date (current date + 2 hours)
export const getStartDate = () => {
  const now = new Date();
  now.setHours(now.getHours() + 2); // Add 2 hours
  return formatDateTime(now);
};

// Function to calculate the end date (next day at the same time)
export const getEndDate = () => {
  const now = new Date();
  now.setDate(now.getDate() + 1); // Move to the next day
  return formatDateTime(now);
};

//to add ** in string such as password or secret key
export const maskedSecretKey = (string) => {
  const n = string.length;

  // splice the last four characters
  const lastFour = string.slice(-8);
  const remaining = string.slice(0, n - 8);

  // mask the remaining numbers with asterisks
  const masked = "*".repeat(remaining.length) + lastFour;

  return masked;
};

// Function to convert Date to ISO format
export function convertToISOFormat(dateString) {
  // Parse the input date string into a Date object
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid date string");
    return;
  }

  // Extract components for the ISO format
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Format the date in 'YYYY-MM-DDTHH:mm' format
  const isoFormattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

  return isoFormattedDate;
}

//this can be used for restricing selecting past time
const filterPassedTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() < selectedDate.getTime();
};

export function parseDateWithCurrentYear(dateString, ISOFormat) {
  // Get the current year
  const currentYear = new Date().getFullYear();

  // Check if the year is missing in the input date string
  // \d{4} specifically looks for four consecutive digits in the string
  if (!/\d{4}/.test(dateString)) {
    // Append the current year to the date string
    dateString += `, ${currentYear}`;
  }

  if (ISOFormat) {
    const date = new Date(dateString);
    // Extract components for the ISO format
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Format the date in 'YYYY-MM-DDTHH:mm' format
    const isoFormattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

    return isoFormattedDate;
  } else {
    // Now create the date object
    return new Date(dateString);
  }
}

export const authorizedUser = (res) => {
  if (res?.code === 440) {
    localStorage.clear("p");
    window.location.href = `/auth/login?error=Your session has expired`;
  } else {
    return;
  }
};

//a function which converts UTC to local time of user
export const convertUTCToLocal = (utcDate) => {
  const isoFormattedDate = convertToISOFormat(utcDate); //first convert in ISO format
  return moment.utc(isoFormattedDate).local().format("D MMM YYYY, h:mm a"); // Convert UTC to local time
};

// Utility function to show the "Please Wait" warning when campaign is in progress
export const showInProgressAlert = () => {
  return Swal.fire({
    title: "Bulk Invite Sending in Progress",
    text: "Please wait until the process complete before performing this action.",
    icon: "info",
    timer: 4000,
    showConfirmButton: false,
  });
};

//make number comma seperated according to international number system
export const convertCommaSeperateNumToString = (num) => {
  return new Intl.NumberFormat("en-US").format(num);
};
