import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
} from "reactstrap";
// core components
import { USER_REGISTRATION } from "API/apiConstants";
import { useNavigate } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "API/apiConstants";
import { headerColor } from "components/Common/constants";

function RegistrationDetails(props) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null); // Add state for reCAPTCHA
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    if (isNotificationOpen) return;
    setIsNotificationOpen(true);
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      const strength = getPasswordStrength(value);
      setPasswordStrength(strength);
    }

    setFormData({ ...formData, [name]: value });

    // Remove error when user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    validateField(name, value);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case "Strong":
        return "text-success";
      case "Medium":
        return "text-warning";
      case "Weak":
      default:
        return "text-danger";
    }
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const getPasswordStrength = (password) => {
    let strength = "Weak";
    if (
      password.length >= 6 &&
      /\d/.test(password) &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password)
    ) {
      strength = "Strong";
    } else if (
      password.length >= 6 &&
      (/[a-z]/.test(password) || /[A-Z]/.test(password))
    ) {
      strength = "Medium";
    }
    return strength;
  };

  const validateField = (name, value) => {
    let error = "";
    // Regular expressions
    // const textOnlyRegex = /^[a-zA-Z]+$/;
    // const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    const strength = getPasswordStrength(value);

    switch (name) {
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value) {
          error = "Email is required.";
        } else if (!emailRegex.test(value)) {
          error = "Please enter a valid email address.";
        }
        break;

      case "password":
        if (!value) {
          error = "Password is required.";
        } else if (value.length < 6) {
          error = "Password must be at least 6 characters long.";
        } else if (value.length > 32) {
          error = "Password must be less than 32 characters.";
        } else if (strength === "Weak" || strength === "Medium") {
          error = "Please choose a stronger password.";
        }
        break;

      default:
        if (!value) {
          error = "This field is required.";
        }
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const validateForm = () => {
    let isValid = true;
    const fieldsToValidate = ["email", "password"];
    fieldsToValidate.forEach((field) => {
      validateField(field, formData[field]);
      if (errors[field]) {
        isValid = false;
      }
    });

    return isValid;
  };

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setPasswordVisible(!passwordVisible);
    } else if (field === "confirmPassword") {
      setConfirmPasswordVisible(!confirmPasswordVisible);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      props.setIsLoading(true);
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      fetch(USER_REGISTRATION, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(formData),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.code === 200) {
            localStorage.setItem("p", JSON.stringify(res.data));
            props.setIsLoading(false);
            navigate("/a/dashboard");
          } else {
            props.setIsLoading(false);
            if (Array.isArray(res.message)) notify("error", res.message[0].Msg);
            else notify("error", res.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          notify(
            "error",
            "Something went wrong try again later or login with credential."
          );
        });
    }
  };

  const hasErrorsOrEmptyFields = () => {
    const isEmpty = Object.values(formData).some(
      (field) => field.trim() === ""
    );
    const hasErrors = Object.values(errors).some((error) => error !== "");
    return (
      isEmpty || hasErrors || passwordStrength !== "Strong" || !recaptchaValue
    );
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full viewport height
            width: "100vw", // Full viewport width
            backgroundColor: "white", // Blank screen background color
          }}
        >
          <i className="fa fa-spinner fa-spin fa-3x text-black" />
        </div>
      )}
      <div className="rna-wrapper1">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {/* <AuthHeader title="Create Forever Free Account" /> */}

      <>
        {/* <CardBody className="px-lg-5 py-lg-4"> */}
        <Form role="form" onSubmit={handleSubmit}>
        {/* Please do not remove below commented code of email and password inputs. It may require in future */}
         {/* <Row>
            <Col md="12" className="mb-3">
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  className={errors.email ? "is-invalid" : ""}
                />
              </InputGroup>
              {errors.email && (
                <small className="text-danger">{errors.email}</small>
              )}
            </Col>
            <Col md="12">
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-lock" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="password"
                  placeholder="New Password"
                  type={passwordVisible ? "text" : "password"}
                  value={formData.password}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  className={errors.password ? "is-invalid" : ""}
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i
                      className={`fa ${
                        passwordVisible ? "fa-eye-slash" : "fa-eye"
                      }`}
                      aria-hidden="true"
                      onClick={() => togglePasswordVisibility("password")}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              {errors.password && (
                <small className="text-danger">{errors.password}</small>
              )}
              <div className="text-muted">
                <small>
                  Password strength:{" "}
                  <span className={getPasswordStrengthColor()}>
                    {passwordStrength}
                  </span>
                </small>
              </div>
            </Col>
            <ReCAPTCHA
              sitekey={SITE_KEY}
              onChange={onRecaptchaChange}
              className="ml-3 mt-2"
            />
          </Row>
          <div className="text-center">
            <Button
              className="mt-3"
              style={styles.buttonStyle}
              type="submit"
              disabled={hasErrorsOrEmptyFields()}
            >
              Create account
            </Button>
          </div> */}
          <div className="text-center mt-3 mb--2">
            <Label className="text-center">
              Already Have An Account?{" "}
              <a
                style={styles.loginText}
                className="text-[#0da5c0]"
                onClick={() => navigate("/auth/login")}
              >
                Login
              </a>
            </Label>
          </div>
          <Row className="d-flex justify-content-center">
          <div className="ml-3 mt-2 text-center" style={styles.terms}>
            <label className="text-center">
              <span>
                By proceeding, you agree to our
                 {/* By clicking the "Create Account" button, you agree to our{" "} */}
              </span>
              <a
                className="text-blue ml-1"
                href="https://www.bulkcalendar.com/terms-of-service/"
                target="_blank"
                rel="noopener noreferrer"
              >
               Terms of Service
              </a>{" "}
              <span className="text-center">
                and
                <a
                  className="text-blue ml-1"
                  href="https://www.bulkcalendar.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  Privacy Policy.
                </a>
              </span>
            </label>
          </div>
          </Row>
        </Form>
      </>
    </>
  );
}

export default RegistrationDetails;
const styles = {
  terms: { fontSize: "12px" },
  buttonStyle: {
    backgroundColor: headerColor,
    color: "white",
  },
  loginText: {
    cursor: "pointer",
    fontSize: "1rem",
  },
};
