// Mailing service information
export const serviceInfo = {
  0: "Select an Email Service, enter the necessary details, and activate it to send calendar invites.",
  1: "SendGrid is a top email delivery platform. By connecting SendGrid’s API to BulkCalendar, you can easily send as many calendar invites as you need.",
  2: "Brevo (formerly SendInBlue) is a top email delivery platform. By connecting Brevo’s API to BulkCalendar, you can easily send as many calendar invites as you need. ",
  3: "With the Custom SMTP option, you can connect your email to BulkCalendar.  And by using your own email you can send personalized calendar invites easily.",
  4: `To set up your Gmail with BulkCalendar. Click the "Configure with Google" button and follow the instructions.`,
  6: "Mailgun is a powerful email delivery platform. By connecting Mailgun’s API to BulkCalendar, you can easily send as many calendar invites as you need.",
};

//name of mailing service
export const mailingServiceName = {
  0: "Email Integration",
  1: "SendGrid",
  2: "Brevo",
  3: "Custom SMTP",
  4: "Gmail (Google)",
  6: "Mailgun",
};

// Mailing service sub text
export const serviceSubText = {
  1: "Please watch the below video for a simple guide on how to set up SendGrid with BulkCalendar.",
  2: "Please watch the below video for a simple guide on how to set up Brevo with BulkCalendar.",
};

// Mailing service websites
export const mailingServiceWeb = {
  1: "https://sendgrid.com/en-us",
  2: "https://www.brevo.com/",
  6: "https://www.mailgun.com/",
};

//Mailing service note
export const serviceNote = {
  // 0: "SMTP setup requires server, port, credentials, encryption (SSL/TLS), and authentication for secure email delivery.",
  1: "To ensure optimal email delivery, please make sure that the “Sender email” and “Domain” are correctly configured and validated in your SMTP provider settings.",
  2: "To ensure optimal email delivery, please verify that the “Sender email address” and “Domain” are correctly configured and validated within your SMTP provider settings.",
  3: "To ensure optimal email delivery, please verify all the required details are correctly configured and validated within your SMTP provider settings.",
  4: "You can send up to 500 calendar invites per day for a regular Gmail account. For a Google Workspace account, the limit is 2,000 calendar invites per day.",
  6: "To ensure optimal email delivery, please make sure that the “Sender email address” and “Domain” are correctly configured and validated in your Mailgun account settings.",
};
