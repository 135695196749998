import React, { useEffect, useRef, useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Label,
  UncontrolledTooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// core components
import { useLocation, useNavigate } from "react-router-dom";
import { getCampaignDetails } from "action/campaign/createCampaign";
import { useDispatch, useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";
import CampaignAccordian from "./CampaignAccordian";
import InviteSendingProgress from "./InviteSendingProgress";
import {
  clearAttendeeListFrmStore,
  clearCampaignDetailsFrmStore,
} from "action/campaign/createCampaign";
import { cyanBlue } from "components/Common/constants";
import { BulkSendCalendarInviteModal } from "./BulkSendModal";
import CountsCard from "../Common/CountsCard";
import Warning from "components/Common/Warning";
import { CAMPAIGN_STATUS_IN_PROGRESS } from "components/Common/constants";
import { createDuplicateCampaign } from "action/campaign/createCampaign";
import Swal from "sweetalert2";

function CampaignDetails() {
  const [cardFilter, setCardFilter] = useState("");
  const [campaignCode, setCampaignCode] = useState(null);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const notificationAlertRef = React.useRef(null);
  //the emailSent state is uplifted from InviteProgressBar
  // because this can be used to real time sent and unsent counts when campaign status is In-Progress
  const [duplicateConfirm, setDuplicateConfirm] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [inviteCount, setInviteCount] = useState({
    sentCount: 0,
    pendingCount: 0,
    failedCount: 0,
    attendeeCount: 0,
  });
  const campaignDetails = useSelector(
    (state) => state.createCampaign.campaignDetails
  );
  const tableRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state?.campaignCode) {
      setCampaignCode(location.state?.campaignCode);
      getCampaignDetails(dispatch, location.state?.campaignCode);
    } else {
      navigate("/a/campaigns");
    }
    return () => {
      clearAttendeeListFrmStore(dispatch);
      clearCampaignDetailsFrmStore(dispatch);
    };
  }, []);

  const handleCardClick = (filterType) => {
    setCardFilter(filterType);
    tableRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const notify = (type, msgObj) => {
    if (isNotificationOpen && !msgObj.showForceFully) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {msgObj.message}
          </span>
          <span data-notify="message">{msgObj.subMessage}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };

  const duplicateCampaign = async () => {
    setLoader(true);
    const res = await createDuplicateCampaign(campaignCode, {
      copyAttendee: isChecked ? "YES" : "NO",
    });
    if (res.code === 200) {
      setLoader(false);
      navigate("/a/campaigns/step-1", {
        state: {
          campaignCode: res.data,
        },
      });
    } else {
      setLoader(false);
      confirmAlert("Failed!", res.message, "error");
    }
  };

  const confirmAlert = (title, msg, type) => {
    Swal.fire({
      title: title,
      text: msg,
      showConfirmButton: false,
      showCancelButton: false,
      timer: 3000,
      icon: type,
    });
  };

  const duplicateWarning = (row) => {
    //condition checked that if calendar is in progress so to restrict further action
    setDuplicateConfirm(true);
  };

  const handleDuplicateModal = () => {
    setDuplicateConfirm(false);
    setIsChecked(false);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <Modal
        isOpen={duplicateConfirm}
        toggle={handleDuplicateModal}
        // style={styles.videoContainer}
        className={`modal-dialog-centered`}
        backdrop={"static"}
      >
        <ModalHeader>
          <span className="form-control-label" style={{ fontSize: "1.8rem" }}>
            Create Duplicate
          </span>
        </ModalHeader>
        <ModalBody className="mb--4">
          <div className="custom-control custom-checkbox custom-checkbox-primary mb-3 ">
            <input
              className="custom-control-input"
              id="customCheck2"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label
              className="custom-control-label ml--1"
              htmlFor="customCheck2"
            >
              Make a duplicate of Attendees also.
            </label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => handleDuplicateModal()}
            color="error"
            disabled={loader}
          >
            Cancel
          </Button>
          <Button
            variant="info"
            onClick={duplicateCampaign}
            disabled={loader} // Disable button while loading
            color="info"
            style={{ minWidth: "6rem" }} // Set a fixed min-width
          >
            {loader ? (
              <i className="fa fa-spinner fa-spin fa-lg text-white" />
            ) : (
              "Create"
            )}
          </Button>
        </ModalFooter>
      </Modal>
      <Warning />
      {/* <SimpleHeader name="Company Name" /> */}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {/* <div className="header bg-info pt-6"></div> */}
      <Container className="mt--6" fluid>
        {!campaignDetails.ID && !campaignDetails.message ? (
          <Card>
            <div style={styles.loaderContainer}>
              <i
                className="fa fa-spinner fa-spin fa-2xl"
                style={styles.loaderColor}
              />
            </div>
          </Card>
        ) : campaignDetails.message ? (
          <Card>
            <div style={styles.loaderContainer}>
              <span className="text-red">{campaignDetails?.code}</span>
              {campaignDetails.message}
            </div>
          </Card>
        ) : (
          <>
            <InviteSendingProgress
              campaignCode={campaignCode}
              dispatch={dispatch}
              notify={notify}
              inviteCount={inviteCount}
              setInviteCount={setInviteCount}
            />
            <Card className="mb-4">
              <CardHeader>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="d-flex justify-content-space-between">
                      <h3 className="mb-2">
                        Calendar: {campaignDetails?.campaignName}
                      </h3>
                      {/* to hide edit action when campaign is in progress */}
                      {campaignDetails?.status !==
                        CAMPAIGN_STATUS_IN_PROGRESS && (
                        <>
                          <div
                            id="tooltipEditDetails"
                            className="d-flex"
                            onClick={(e) =>
                              navigate("/a/campaigns/step-1", {
                                state: {
                                  campaignCode: campaignCode,
                                },
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fas fa-edit text-info ml-3 mt-1" />
                            <p className="ml-1 text-info font-weight-bold">
                              Edit
                            </p>
                          </div>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltipEditDetails"
                            placement="bottom"
                          >
                            Edit Calendar
                          </UncontrolledTooltip>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col xs="12" md="6" className="text-md-right mt-2 mt-md-0">
                    <div className="d-flex justify-content-end gap-4 flex-column flex-md-row ">
                      <Button
                        className="mr-0 mr-md-2 mb-md-0 mb-2"
                        color="info"
                        onClick={(e) => duplicateWarning()}
                      >
                        Create Duplicate
                      </Button>

                      {(campaignDetails?.status === "PENDING" ||
                        campaignDetails?.status === "MODIFIED") &&
                        campaignDetails?.unsendCount > 0 && (
                          <BulkSendCalendarInviteModal
                            availableCredits={
                              campaignDetails?.tenantAvailableCredits
                            }
                            creditsToBeUsed={campaignDetails?.unsendCount}
                            campaignCode={campaignCode}
                            numberOfRecipients={campaignDetails?.unsendCount}
                            notify={notify}
                            dispatch={dispatch}
                            navigate={navigate}
                          />
                        )}
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row md={12}>
                  <Col md={6} className="mb-2">
                    <Label className="mr-2">Calendar Status - </Label>
                    <Label
                      style={{
                        fontWeight: "500",
                        color:
                          campaignDetails?.status === "FAILED"
                            ? "red"
                            : campaignDetails?.status === "IN-PROGRESS"
                            ? "orange"
                            : campaignDetails?.status === "COMPLETED"
                            ? "green"
                            : campaignDetails?.status === "RETRY"
                            ? "Brown"
                            : campaignDetails?.status === "MODIFIED"
                            ? "purple"
                            : "SteelBlue", //for PENDING
                      }}
                    >
                      {campaignDetails?.status}
                    </Label>
                    {campaignDetails?.status === "RETRY" && (
                      <small className="text-muted ml-2">
                        (System will retry sending invites in sometime.)
                      </small>
                    )}
                  </Col>
                  {campaignDetails?.failedRemark !== "" && (
                    <Col md={6} className="mb-2">
                      <Label className="mr-2">Failed Reason - </Label>
                      <Label
                        style={{
                          fontWeight: "500",
                          color: "red",
                        }}
                      >
                        {campaignDetails?.failedRemark}
                      </Label>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
            <CountsCard
              tableRef={tableRef}
              onCardClick={handleCardClick}
              setCardFilter={setCardFilter}
              showStatus={false}
              campaignDetails={campaignDetails}
              inviteCount={inviteCount}
            />
            <Card>
              <CardBody>
                <Row md={12} className="mt-2">
                  <Col md={4}>
                    <Label>Start Date</Label>
                    <p className="button">{campaignDetails?.startAt}</p>
                  </Col>
                  <Col md={4}>
                    <Label>End Date</Label>
                    <p className="button">{campaignDetails?.endAt}</p>
                  </Col>
                  <Col md={4}>
                    <Label>Time Zone</Label>
                    <p className="button">
                      {campaignDetails?.timeZone?.location}
                    </p>
                  </Col>
                </Row>
                <Row md={12} className="mt-4">
                  <Col md={4}>
                    <Label>Organizer Name</Label>
                    <p className="button">{campaignDetails?.organizerName}</p>
                  </Col>
                  <Col md={4}>
                    <Label>Organizer Email</Label>
                    <p className="button">{campaignDetails?.organizerEmail}</p>
                  </Col>
                  <Col md={4}>
                    <Label>Integrations</Label>
                    <p className="button">
                      {campaignDetails?.emailConfiguration?.title}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div ref={tableRef}>
              <CampaignAccordian
                campaignDetails={campaignDetails}
                campaignCode={campaignCode}
                notify={notify}
                dispatch={dispatch}
                cardFilter={cardFilter}
                handleCardClick={handleCardClick}
              />
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default CampaignDetails;

const styles = {
  cusrorPointer: { cursor: "pointer" },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "70vh",
    flexDirection: "column",
  },
  loaderColor: { color: cyanBlue },
};
