import EmailConfiguration from "pages/Settings/EmailConfig/EmailConfiguration";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

function EmailConfigModal({
  isOpen,
  toggleAddConfigModal,
  notify,
  createCampaignFields,
  campaignCode,
}) {
  return (
    <Modal isOpen={isOpen} size="lg">
      <ModalHeader toggle={() => toggleAddConfigModal(false)}>
        Add Integration
      </ModalHeader>
      <ModalBody className="p-2 pb-4">
        <EmailConfiguration
          fromCalendar={true} //this flag taken to understand that that component is used for calendar modal
          toggleAddConfigModal={toggleAddConfigModal}
          modalNotify={notify}
          createCampaignFields={createCampaignFields}
          campaignCode={campaignCode}
        />
      </ModalBody>
    </Modal>
  );
}

export default EmailConfigModal;
