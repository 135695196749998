import React from "react";
import { Button, Card, CardBody, Row, Col, Container, Label } from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { useLocation, useNavigate } from "react-router-dom";

// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import RegistrationDetails from "./RegistrationDetails";
import { GOOGLE_SIGN_UP } from "API/apiConstants";
import { Backdrop, Divider } from "@mui/material";
import ServerIndicator from "components/Common/ServerIndicator";
import { lightBlue, CONTACT_US, logoTopColor, googleBtnColor, headerColor, BOOK_DEMO_LINK} from "components/Common/constants";

function Registration() {
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const navigate = useNavigate();
  const token = localStorage.getItem("p")
    ? JSON.parse(localStorage.getItem("p"))?.token
    : null;
  const notificationAlertRef = React.useRef(null);
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [copiedText, setCopiedText] = React.useState("");

 //on click of copy icon
 const handleCopyClick = async (e, text) => {
  e.stopPropagation();
  try {
    await window.navigator.clipboard.writeText(text);
    setCopiedText(text);
    setTimeout(() => {
      setCopiedText("");
    }, 1000);
  } catch (err) {
    alert("Copy to clipboard failed.");
  }
};

  const notify = (type, message) => {
    if (isNotificationOpen) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };

    notificationAlertRef.current?.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 3000); // Match the autoDismiss time (7000ms = 7 seconds)
  };

  React.useEffect(() => {
    if (location.state?.msg) notify("success", location.state?.msg);
  }, [location.state?.msg]);

  // Function to extract query parameters from URL
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    const name = searchParams.get("name");
    const role = searchParams.get("role");
    const error = searchParams.get("error");
    if (token && name && role) {
      navigate("/a/dashboard");
      const p = {
        token: token,
        name: name,
        role: role,
      };
      localStorage.setItem("p", JSON.stringify(p));
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    } else if (error) {
      notify("error", error);
    }
  }, []);
  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  React.useEffect(() => {
    if (token) navigate("/a/dashboard");
  }, [token]);

  const handleGoogleSignup = () => {
    setIsLoading(true);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    fetch(`${GOOGLE_SIGN_UP}`, {
      method: "GET",
      headers: headers,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 200) {
          setIsLoading(false);
          window.location.href = res.data;
        } else {
          setIsLoading(false);
          notify(
            "error",
            res.message || "An error occurred during Google registration."
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        alert(
          "Something went wrong try again later or register with credential."
        );
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} // Ensures it's on top of other elements
        open={isLoading}
      >
        <i className="fa fa-spinner fa-spin fa-3x text-black" />
      </Backdrop>

      <>
        <div className="rna-wrapper1">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <AuthHeader />
        <Container className="mt--7 pb-8">
          <Card
            className="bg-secondary border-0 mb-0"
            xs={12}
            sm={12}
            style={{
              background:
                windowWidth <= 768
                  ? "linear-gradient(180deg, #f7fafc 50%, #eeeeee 50%)"
                  : "linear-gradient(90deg, #eeeeee 50%, #f7fafc 50%)",
            }}
          >
            <CardBody className="px-md-1 py-md-1">
              <Row className="d-flex justify-content-center mb-1">
                {windowWidth <= 768 && (
                  <>
                    <Col md={5} className="pl-md-2 p-2">
                    <div className="btn-wrapper text-center mb-0 mt-3">
                       <div style={styles.headerBtn}>
                        Start Free Trial
                      </div>
                    </div>
                      <h4 className="text-center mt--1 mb-4"
                        style={styles.subHeading}> 
                        No Credit Card Required.
                      </h4>
                      <div className="btn-wrapper text-center mb-3">
                        <Button
                          style={styles.googleButton}
                          className=" btn-icon text-white"
                          // color="blue"
                          onClick={handleGoogleSignup}
                        >
                          <span className="btn-inner--icon bg-white p-2 ml--2">
                            <img
                              alt="Google Icon"
                              src={
                                require("assets/img/icons/common/google.svg")
                                  .default
                              }
                            />
                          </span>
                          <span className="btn-inner--text" style={{fontSize:"1.3rem"}}>
                            Sign up with Google
                          </span>
                        </Button>
                      </div>
                      {/* Please do not remove below commented code of email and password inputs. It may require in future */}
                      {/* <Divider>or</Divider> */}
                      <div
                        className="text-center text-muted mb-7 mt-3"
                        style={styles.heading2}
                      ></div>
                      <RegistrationDetails setIsLoading={setIsLoading} />
                    </Col>
                  </>
                )}
                <>
                  <Col md={5}>
                    <h2
                      className="text-#0da5c0 text-left mt-4 mb-3"
                      style={styles.heading3}
                    >
                      Prime Features
                    </h2>
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-md">
                        Send Free Calendar Invites in Mass
                      </Label>
                    </div>

                    {/* <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-lg">2 Campaigns </Label>
                    </div> */}
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-md">
                        Auto Block Attendees’ Calendar instantly
                      </Label>
                    </div>
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-md">
                        Increase Attendees Participation
                      </Label>
                    </div>
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-md">
                        Easy Email & Domain Integration
                      </Label>
                    </div>
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-md">
                        Personalized Calendar Invites
                      </Label>
                    </div>
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-md" >
                        Easy Upload of Attendee List
                      </Label>
                    </div>
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-md" >
                        Supports All Calendars (Google, Outlook, ical etc.)
                      </Label>
                    </div>
                    <div style={styles.divStyle}>
                      <i
                        className="fa fa-check mt--2 text-green"
                        style={styles.iconStyle}
                      />
                      <Label className="p-1 text-md" >
                        Initial On-Call Support & Ticket-Based Support
                      </Label>
                    </div>
                    
                    <div style={{...styles.divStyle, ...styles.cursor}} onClick={() => setIsExpanded(!isExpanded)}>
                      <i
                        className="fa fa-phone mt--2 text-green"
                        style={{ ...styles.iconStyle, fontSize: '0.85rem' }}
                      />
                      <Label className="p-1 text-md mr-4" style={{cursor:"pointer",color: lightBlue}}>
                        Available for Support via Call, Chat, & Email
                      </Label>
                      <i
                        className={`mt--2 fa-solid fa-chevron-${isExpanded ? "down" : "right"} fa-sm `}
                      />
                    </div>
                    {isExpanded && (
                      <div className="mt--2 p-2 bg-gray-100 rounded-md shadow-sm">
                      {CONTACT_US.map(({ icon, color, action, label, copyText }, index) => (
                        <div key={index} className="ml-1">
                          <div className="d-flex align-items-center ml-3" style={styles.contactItem}>
                            <span
                              className={`btn-icon-only rounded-circle btn-sm ml--1 ${color}`}
                              onClick={() => (action.startsWith("http") ? window.open(action) : (window.location.href = action))}
                            >
                              <i className={`fa-solid ${icon}`} style={styles.cursor}/>
                            </span>
                            <span
                              className="mt--3 ml--1"
                              onClick={() => (action.startsWith("http") ? window.open(action) : (window.location.href = action))}
                              style={styles.cursor}
                            >
                              {label}
                            </span>
                            {copyText && (
                              copiedText === copyText ? (
                                <span className="ml-1 mt--3" style={styles.contactItem}>copied!</span>
                              ) : (
                                <div
                                  className="icon-action ml-2 mt--3"
                                  style={styles.cursor}
                                  onClick={(e) => handleCopyClick(e, copyText)}
                                >
                                  <i className="fa-regular fa-copy fa-sm" />
                                </div>
                              )
                            )}
                          </div>
                          
                        </div>
                        
                      ))}
                    </div>
                    )}

                    <div style={{...styles.divStyle, ...styles.cursor}} className={`mt-${isExpanded?-2:0.9}`}
                      onClick={() => 
                        window.open(
                          BOOK_DEMO_LINK,
                          "_blank" 
                        )
                      }
                    >
                      <i
                        className="fa fa-calendar-user mt--2 text-green"
                        style={styles.iconStyle}
                      >   🧑‍💻
                        </i>
                      <Label className="p-1 text-md" style={{cursor:"pointer",  color: lightBlue}}>
                        Book A Free Demo Here
                      </Label>
                    </div>
                  </Col>

                  {/* Vertical line */}
                  <Col
                    md={1}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <div style={styles.verticalLine} />
                  </Col>
                  {windowWidth >= 768 && (
                    <Col md={5} className="pl-md-2 p-2 mt-7">
                      <div className="btn-wrapper text-center mb-0 mt-3">
                       <div style={styles.headerBtn}>
                        Start Free Trial
                      </div>
                      </div>
                      <h4 className="text-center mt--1 mb-4"
                        style={styles.subHeading}> 
                        No Credit Card Required.
                      </h4>
                      <div className="btn-wrapper text-center mb-3">
                        <Button
                          style={styles.googleButton}
                          className=" btn-icon text-white"
                          // color="blue"
                          onClick={handleGoogleSignup}
                        >
                          <span className="btn-inner--icon bg-white p-2 ml--2">
                            <img
                              alt="Google Icon"
                              src={
                                require("assets/img/icons/common/google.svg")
                                  .default
                              }
                            />
                          </span>
                          <span className="btn-inner--text" style={{fontSize:"1.4rem"}}>
                            Sign up with Google
                          </span>
                        </Button>
                      </div>
                      {/* Please do not remove below commented code of email and password inputs. It may require in future */}
                      {/* <Divider>or</Divider> */}
                      <div
                        className="text-center text-muted mb-8 mt-3"
                        style={styles.heading2}
                      ></div>
                      <RegistrationDetails setIsLoading={setIsLoading} />
                    </Col>
                  )}
                </>
              </Row>
            </CardBody>
          </Card>
        </Container>
        <ServerIndicator />
      </>
    </>
  );
}

export default Registration;
const styles = {
  forgetPass: {
    cursor: "pointer",
    fontSize: "1rem",
  },
  heading2: { fontSize: "1.2rem", fontWeight: "bold" },
  verticalLine: {
    borderLeft: "1px solid #dcdcdc" /* Adjust color to match your theme */,
    height: "100%",
    width: "1px",
  },
  heading: { color: headerColor, fontSize: "2rem" },
  subHeading:{color: headerColor, fontSize: "0.9rem"},
  heading3: { color: headerColor, fontSize: "2rem", fontWeight:"bold"},
  googleButton: {
    backgroundColor: googleBtnColor,
    paddingBottom: "0.9rem",
    paddingTop: "0.5rem",
  },
  divStyle: { display: "flex", alignItems: "center" },
  iconStyle: { marginRight: "0.5rem" },
  headerBtn:{ 
    // backgroundColor: logoTopColor, 
    width:" 100%", maxWidth: "20rem", 
    height:"3.2rem", display: "inline-block", borderRadius:"0.3rem",
    color: "grey", fontSize: "1.8rem",margin:"0",fontWeight:"bold",
    padding: "0.2rem 1rem", 
},
  contactItem: { fontSize: "0.9rem" },
  cursor: { cursor: "pointer" },
};
